const initialState = {
    counter: 12,
    name:"Good2swim",
}
export default function reducer(state = initialState,action){
    switch(action.type){
        case "INCREMENT":
            return {counter: state.counter+1,name:state.name}
        case "DECREMENT":
            return {counter: state.counter-1,name:state.name}
        default:
            return state;
    }
}