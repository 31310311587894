import React, { useState, useEffect } from "react";
import logo from "../icon/good2swim-logo.png";
import bgPic from "../icon/EW2A1883.jpg";
import getUrl from '../function/getUrl'

const url=getUrl()
//trial 體驗報名
const Trial = (props) => {
  const allowData = (
    <>
    <div className="container vw-100 " style={{maxWidth:"700px"}}>
      <div className="card border-0">
        <div class="card-header alert-warning">寶貝體驗報名 注意事項</div>
        <div class="card-body">
          <p class="card-text text-muted text-left">
            ①館內每位下水的ＢＡＢＹ都必須穿著寶寶游泳專用泳褲及一次性游泳尿布，才可以入池參加活動。若無法及時自行準備，可於現場購買買瑞典品牌
            ImseVimse 寶寶泳褲（市價 $880) 或一次性游泳尿布（單價60元)。
            <br />
            <br />
            ②每名學員僅限報名體驗一次，優惠費用為1000元(含一大一小)，最晚請於預約體驗時段前
            3
            日完成付款，為了確保寶貝的體驗名額，請完成匯款並告知後五碼才算預約成功，否則系統自動取消恕不保留體驗名額！，方可正式保留體驗名額。
            <br />
            <br />
            ③為確保給首次下水的寶貝最完整的照顧及說明，報名體驗的學員會安排適齡體驗時段。體驗後可再挑選適合之常規時段（週二～週日全天均有開放）
            <br />
            <br />
            ④若有不可抗拒或身體不適等因素，均可再更改預約體驗時段。
            <br />
            <br />
            ⑤請務必填寫正確的FB名稱並主動私訊好好游粉絲團，或是加入 好好游Line@
            群組，並主動告知寶貝的姓名，以加速小編搜尋您的帳號並儘速聯絡安排體驗時間。
            <br />
            <br />
            ⑥本次資料收集僅作本次體驗使用，為了解您和寶寶過去的經驗以提供適合的服務，煩請您確實填寫
            <br />
            <br />
            ⑦此次體驗活動期間，本公司可能會拍攝照片及影片以供本公司網站、粉絲專業、社群媒體使用。若您及寶寶的肖像權不願意授權給本公司使用，請於活動開始前主動以書面告知本公司。
            <br />
          </p>

          <div
            className="form-group card border-0 p-2  "
            style={{ width: "100%" }}
          >
            <button
              className="btn btn-danger p-1"
              onClick={handelAllow}
              style={{ width: "100%" }}
            >
              {" "}
              我已確認注意事項
            </button>
          </div>
        </div>
      </div>
      </div>
    </>
  );

  const trailData = (
    <>
     <div className="container vw-100 " style={{maxWidth:"600px"}}>
      <form>
        <div className="card mt-2 border-0">
          <div className="card-header alert-info">Baby 資料區</div>
          <div className="card-body">
            <div className="m-1">
              <div className="input-group">
                <div className="input-group-prepend">
                  <span
                    className="input-group-text alert-info"
                    id="validationTooltipUsernamePrepend"
                  >
                    姓名
                  </span>
                </div>
                <input
                  type="text"
                  id="name"
                  className="form-control"
                  placeholder="請填寫中文姓名"
                  aria-describedby="validationTooltipUsernamePrepend"
                  required
                />
              </div>
            </div>
            <div className="m-1">
              <div className="input-group">
                <div className="input-group-prepend">
                  <span
                    className="input-group-text alert-info"
                    id="validationTooltipUsernamePrepend"
                  >
                    小名
                  </span>
                </div>
                <input
                  type="text"
                  id="nickName"
                  className="form-control"
                  id="validationTooltipUsername"
                  placeholder="可填寫暱稱、英文姓名"
                  aria-describedby="validationTooltipUsernamePrepend"
                  required
                />
              </div>
            </div>

            <div className="m-1">
              <div className="input-group">
                <div className="input-group-prepend">
                  <span
                    className="input-group-text alert-info"
                    id="validationTooltipUsernamePrepend"
                  >
                    生日
                  </span>
                </div>
                <input
                  type="date"
                  id="birthday"
                  className="form-control"
                  aria-describedby="validationTooltipUsernamePrepend"
                  required
                />
              </div>
            </div>

            <div className="m-1">
              <div className="input-group">
                <div className="input-group-prepend">
                  <span
                    className="input-group-text alert-info"
                    id="validationTooltipUsernamePrepend"
                  >
                    性別
                  </span>
                </div>

                <select id="gender" className="custom-select" required>
                  <option selected>請選擇...</option>
                  <option value="男">Boy</option>
                  <option value="女">Girl</option>
                </select>
              </div>
            </div>

            <div className="m-1">
              <div className="input-group">
                <div className="input-group-prepend">
                  <span
                    className="input-group-text alert-info"
                    id="validationTooltipUsernamePrepend"
                  >
                    游泳經驗
                  </span>
                </div>
                <input
                  type="text-area"
                  id="swimExp"
                  className="form-control"
                  placeholder="請描述游泳經驗"
                  aria-describedby="validationTooltipUsernamePrepend"
                  required
                />
              </div>
            </div>

            <div className="m-1">
              <div className="input-group">
                <div className="input-group-prepend">
                  <span
                    className="input-group-text alert-info"
                    id="validationTooltipUsernamePrepend"
                  >
                    健康狀況
                  </span>
                </div>
                <input
                  type="text-area"
                  id="healthMemo"
                  className="form-control"
                  placeholder="有無特殊體況"
                  aria-describedby="validationTooltipUsernamePrepend"
                  required
                />
              </div>
            </div>
          </div>
        </div>

        <div className="card mt-2 border-0">
          <div className="card-header alert-primary">家長 資料區</div>
          <div className="card-body">
            <div className="m-1">
              <div className="input-group">
                <div className="input-group-prepend">
                  <span
                    className="input-group-text alert-primary"
                    id="validationTooltipUsernamePrepend"
                  >
                    姓名
                  </span>
                </div>
                <input
                  type="text"
                  id="parent"
                  className="form-control"
                  placeholder="請填寫真實姓名"
                  aria-describedby="validationTooltipUsernamePrepend"
                  required
                />
              </div>
            </div>
            <div className="m-1">
              <div className="input-group">
                <div className="input-group-prepend">
                  <span
                    className="input-group-text alert-primary"
                    id="validationTooltipUsernamePrepend"
                  >
                    稱謂
                  </span>
                </div>
                <input
                  type="text"
                  id="title"
                  className="form-control"
                  placeholder="填寫稱謂"
                  aria-describedby="validationTooltipUsernamePrepend"
                  required
                />
              </div>
            </div>

            <div className="m-1">
              <div className="input-group">
                <div className="input-group-prepend">
                  <span
                    className="input-group-text alert-primary"
                    id="validationTooltipUsernamePrepend"
                  >
                    行動電話
                  </span>
                </div>
                <input
                  type="text"
                  id="phone"
                  className="form-control"
                  placeholder="必填項目"
                  aria-describedby="validationTooltipUsernamePrepend"
                  required
                />
              </div>
            </div>

            <div className="m-1">
              <div className="input-group">
                <div className="input-group-prepend">
                  <span
                    className="input-group-text alert-primary"
                    id="validationTooltipUsernamePrepend"
                  >
                    Line帳號
                  </span>
                </div>
                <input
                  type="text"
                  id="line"
                  className="form-control"
                  placeholder="必填項目"
                  aria-describedby="validationTooltipUsernamePrepend"
                  required
                />
              </div>
            </div>

            <div className="m-1">
              <div className="input-group">
                <div className="input-group-prepend">
                  <span
                    className="input-group-text alert-primary"
                    id="validationTooltipUsernamePrepend"
                  >
                    FB帳號
                  </span>
                </div>
                <input
                  type="text"
                  id="fb"
                  className="form-control"
                  placeholder="請填寫FB顯示名稱"
                  aria-describedby="validationTooltipUsernamePrepend"
                  required
                />
              </div>
            </div>

            <div className="m-1">
              <div className="input-group">
                <div className="input-group-prepend">
                  <span
                    className="input-group-text alert-primary"
                    id="validationTooltipUsernamePrepend"
                  >
                    曾私訊好好游小編
                  </span>
                </div>

                <select
                  id="contactGood2swim"
                  className="custom-select"
                  required
                >
                  <option selected>請選擇...</option>
                  <option value="Yes">Yes </option>
                  <option value="No">No 稍等立即私訊小編</option>
                </select>
              </div>
            </div>

            <div className="m-1">
              <div className="input-group">
                <div className="input-group-prepend">
                  <span
                    className="input-group-text alert-primary"
                    id="validationTooltipUsernamePrepend"
                  >
                    E-mail
                  </span>
                </div>
                <input
                  type="email"
                  id="email"
                  className="form-control"
                  placeholder="E-mail"
                  aria-describedby="validationTooltipUsernamePrepend"
                  required
                />
              </div>
            </div>

            <div className="m-1">
              <div className="input-group">
                <div className="input-group-prepend">
                  <span
                    className="input-group-text alert-primary"
                    id="validationTooltipUsernamePrepend"
                  >
                    期望體驗時段
                  </span>
                </div>

                <select
                  id="wishTime"
                  className="custom-select"
                  multiple="multiple"
                  required
                >
                  <option value="平日上午">平日上午 </option>
                  <option value="平日下午">平日下午 </option>
                  <option value="周末上午">周末上午 </option>
                  <option value="周末下午">周末下午 </option>
                </select>
              </div>
            </div>

            <div className="m-1">
              <div className="input-group">
                <div className="input-group-prepend">
                  <span
                    className="input-group-text alert-primary"
                    id="validationTooltipUsernamePrepend"
                  >
                    介紹人
                  </span>
                </div>
                <input
                  type="text-area"
                  id="introPeople"
                  className="form-control"
                  placeholder="介紹人姓名"
                  aria-describedby="validationTooltipUsernamePrepend"
                />
              </div>
            </div>

            <div className="m-1">
              <div className="input-group">
                <div className="input-group-prepend">
                  <span
                    className="input-group-text alert-primary"
                    id="validationTooltipUsernamePrepend"
                  >
                    備註事項
                  </span>
                </div>
                <input
                  type="text-area"
                  id="memo"
                  className="form-control"
                  placeholder=""
                  aria-describedby="validationTooltipUsernamePrepend"
                  required
                />
              </div>
            </div>
          </div>
        </div>

        <div className="card border-0 mt-2 p-3" style={{ width: "100%" }}>
          <button
            type="submit"
            onClick={handelSummit}
            className="btn btn-info "
          >
            報名體驗去
          </button>
        </div>
      </form>
      </div>
    </>
  );

  const [trialState, setTrialState] = useState(null);
  const [allowState, setAllowState] = useState(allowData);
  function handelAllow() {
    setTrialState(trailData);
    setAllowState(null);
  }

  //捕捉 select中被選的option
  function getSelect(e) {
    let selected = [];
    let optionFollow = e;

    for (let i = 0; i < optionFollow.length; i++) {
      if (optionFollow[i].selected) {
        selected.push(optionFollow[i].value);
      }
    }
    return selected;
  }

  function checkValue(e) {
    let content = e.target.value;
    console.log("name", content);
    if (content == "") {
      alert(e.target.placeholder);
      e.target.focus();
    }
  }

  function handleBirthdayChangeToPassword() {
    let birthdayDate = document.getElementById('birthday').value
   
    return birthdayDate.replaceAll("-", "").substring(4, 8)
     
    
  }

  function handelSummit() {
    var formData = new FormData();
    let name = document.getElementById("name").value;
    alert(name);
    console.log("name", name);
    let nickName = document.getElementById("nickName").value;
    let studentType = "體驗";
    let birthday = document.getElementById("birthday").value;
    let gender = document.getElementById("gender").value;
    let comefrom = "網路";
    let memo = document.getElementById("memo").value;
    let healthMemo = document.getElementById("healthMemo").value;
    let swimExp = document.getElementById("swimExp").value;
    let parent = document.getElementById("parent").value;
    let title = document.getElementById("title").value;
    let phone = document.getElementById("phone").value;
    let password = handleBirthdayChangeToPassword(); //預先設定為生日（月＋日）4碼
    let fb = document.getElementById("fb").value;
    let line = document.getElementById("line").value;
    let contactGood2swim = document.getElementById("contactGood2swim").value;
    let email = document.getElementById("email").value;
    let wishTime = getSelect(document.getElementById("wishTime").options);
    let introPeople = document.getElementById("introPeople").value;

    formData.append("name", name);
    formData.append("nickName", nickName);
    formData.append("studentType", studentType);
    formData.append("birthday", birthday);
    formData.append("gender", gender);
    formData.append("comefrom", comefrom);
    formData.append("memo", memo);
    formData.append("healthMemo", healthMemo);
    formData.append("swimExp", swimExp);
    formData.append("parent", parent);
    formData.append("title", title);
    formData.append("phone", phone);
    formData.append("fb", fb);
    formData.append("line", line);
    formData.append("contactGood2swim", contactGood2swim);
    formData.append("email", email);
    formData.append("nickName", nickName);
    formData.append("wishTime", wishTime);
    formData.append("introPeople", introPeople);
    formData.append("password", password);

    fetch(`${url}/addNewStudent`, {
      method: "POST",
      body: formData,
      mode: "cors",
    })
      .then((res) => {
        return res.json();
      })
      .then((result) => {
        console.log(result["result"]);
        alert(result);
      });
  }
  return (
    <>
      <div className="container vw-75" style={{ maxWidth: "700px" }}>
        <div
          className="card   p-1   border-0"
          style={{ backgroundColor: "#75B8A9" }}
        >
          <img src={bgPic} style={{ width: "100%" }} className="align-middle" />
          <div className="card-body p-1" style={{ backgroundColor: "#D8EFDC" }}>
            <div className="col-sm align-middle text-center ">
              <img src={logo} style={{ width: "50px" }} />
              <h5 className="card-title text-info">好好游 體驗報名專區</h5>
            </div>

            {allowState}
            {trialState}
          </div>
        </div>
      </div>
      
    </>
  );
};

export default Trial;
