import React, { useEffect, useState } from 'react';
import { Route, Routes, useParams, useNavigate } from 'react-router-dom';
import getUrl from '../function/getUrl'


function SqlShow(props) {
    const token=sessionStorage.getItem("token")
    const staffDBID=sessionStorage.getItem("staffDBID")
    if (staffDBID!=5){
        window.location.href="/home"
    }
    const [result, setResult] = useState([])
    const [keys, setKeys] = useState([])
    const url = getUrl()
    const myHeaders = new Headers();
    myHeaders.append('Content-Type', 'application/json');
    myHeaders.append('token', sessionStorage.getItem('token'));


    function handleChange(e) {
        const value = document.getElementById("sqlCommand").value
        console.log(value)
        getSql(value)
      
    }

    
    async function getSql(cmd){
        console.log("cmd",cmd)
        const searchLimit=cmd.search("limit")
        console.log("searchLimit",searchLimit)
        if (cmd==""){
            cmd="SHOW TABLES;"
        }else if (searchLimit==-1){
            cmd+=" limit 10"
        }
        console.log("cmd",cmd)
        const  encoded = encodeURI(cmd)
        await fetch(`${url}/appGet/sqlShow?token=${token}&command=${encoded}`, { mode: "cors" }).then(res => {
            return res.json()
        }).then(json => {

            console.log(json)
            setResult(json)
            console.log(json[0])
            let tempkeys = []
            let tempResult = []
            for (let i in json[0]) {

                tempkeys.push(i)
            }
            setKeys(tempkeys)

            let jsonLen=1
            if (json.length==1){
                jsonLen=0
            }

            for ( let i=jsonLen;i < json.length; i++) {



                tempResult.push(json[i])
            }

            

            setResult(tempResult)
            console.log(tempResult)
        })
    }

    useEffect(async () => {
        const cmd="SHOW TABLES;"
       await getSql(cmd)
    },[])


    return (
        <>



            <div className="container">
                <div className="col-2 fw-bold">資料查詢</div>

                <div className="input-group  mt-1 w-80">
                    <span className="input-group-text" id="basic-addon1">SQL</span>
                    <input type="text" id="sqlCommand" className="form-control" aria-label="Username" aria-describedby="basic-addon1" />
                    <div className='btn btn-danger' onClick={handleChange}>執行</div>
                </div>

            </div>
            <hr />
            <div className=" row p-3">
                <div className="col-2 fw-bold">查詢結果</div>
                <div className="container">
                    <table className="table" style={{ "fontSize": "10px" }}>
                        <thead>
                            <tr>
                                {keys.map(key => {

                                    return <th scope="col">{key}</th>
                                }

                                )}
                            </tr>
                        </thead>
                        <tbody>
                            {result.map(item => {
                                return <tr>{keys.map(key => {
                                    return <td>{item[key]}</td>
                                })}</tr>


                            })}
                        </tbody>
                    </table>

                </div>
            </div>








        </>
    )







}

export default SqlShow;