
import React, {
  useState,
  useEffect,
  useCallback,
  updateState,
  useReducer,
  useContext,
} from "react";
import getUrl from "../function/getUrl.js"
import FunBarClass from "./funBarClass.js";
import { Link, useParams } from "react-router-dom";
import getDay from "../function/getDay.js"
import dateDiff from "../function/dateDiff.js"
import checkLogin from "../function/checkLogin.js"
import checkLevel from "../function/checkLevel.js"

//課程訂單檢視
const OrderView = () => {
  //銷售後 要同步調整 學員身分別
  // 體驗銷售 不得銷售給 正式學員
  //非本人時且權限為1時，不得更改密碼
  checkLogin()
  let param = useParams(); //功能項目
  let orderDBID = param.orderDBID;

  const url = getUrl()

  const token = sessionStorage.getItem("token")
  const operator = sessionStorage.getItem("staffDBID")
  const level = sessionStorage.getItem("level")
  const [studentDBID, setStudentDBID] = useState([])
  const [name,setName]=useState(null)


  const getOrderData = async (orderDBID) => {
    let result = await fetch(`${url}/appGet/orderView?orderDBID=${orderDBID}&token=${token}`).then((res) => {
      return res.json()
    }).then((json) => {

      return json[0]

    }).catch((e) => {
      alert('伺服器錯誤')
    })

    setStudentDBID(result.studentDBID)
    console.log(result)

    document.getElementById('title').innerHTML = ` ${result.name} 課程訂單`
    document.getElementById('name').innerHTML = result.name
    setName(result.name)
    document.getElementById('nickName').innerHTML = result.nickName
    document.getElementById('parent').innerHTML = result.parent
    document.getElementById('orderDate').value = result.orderDate
    document.getElementById('classStartDate').value = result.classStartDate
    document.getElementById('eventName').value = result.eventName
    document.getElementById('eventDBID').value = result.eventDBID

    let tempDate=new Date(result.classStartDate)
     
      let classEndDate=new Date(tempDate.setDate(tempDate.getDate() + parseInt(result.classDays,"10")+parseInt(result.classExtend,"10")))
    console.log(classEndDate)
    document.getElementById('amount').value = result.amount
    document.getElementById('classAmount').value = result.classAmount
    document.getElementById('classDays').value = result.classDays
    document.getElementById('eventPrice').value = result.eventPrice
    document.getElementById('total').value = result.total
    document.getElementById('invoiceID').value = result.invoiceID
    document.getElementById('payment').value = result.payment
    document.getElementById('classStartDate').value = result.classStartDate
    document.getElementById('memo').value = result.memo
    // document.getElementById('classExtend').innerHTML = result.classExtend
    console.log(`${classEndDate.getFullYear()}-${classEndDate.getMonth()+1}-${classEndDate.getDate()}`)
    document.getElementById('classEndDate').innerHTML = `${classEndDate.getFullYear()}-${classEndDate.getMonth()+1}-${classEndDate.getDate()}`


    

  };


function del(){
 

  let code = document.getElementById('securityCode').value
  // alert(code)
  if (code == orderDBID) {
    if (window.confirm(`請確實核備原因後並核準後再行刪除${orderDBID}資料？`)) {
      // alert(`${url}/appGet/turnoffTrial?studentDBID=${studentDBID}&token=${token}`)
      fetch(`${url}/appGet/turnoffOrder?orderDBID=${orderDBID}&token=${token}`).then(res => {
        window.location.href = "/"
      })
    }

  }

}


function handelSummit(e) {
  // let saleItem=salesEvent.find(item=>{
  //   return item.eventDBID==document.getElementById('eventSelected').value
  // })


  let invoiceID=document.getElementById("invoiceID").value
  if (invoiceID==""){
    alert ('發票號碼未填寫')
    return
  }
  let startDate=document.getElementById('classStartDate').value
  let newStartDate=startDate.toString().replaceAll("-","/")
  console.log("newStartDate",newStartDate)
    // console.log(saleItem.eventDBID)
    var formData = new FormData();
    formData.append("orderDBID",param.orderDBID)
    formData.append("studentDBID", studentDBID);
    formData.append("eventDBID", document.getElementById("eventDBID").value);
    formData.append("orderDate", document.getElementById("orderDate").value);
    formData.append("eventName", document.getElementById("eventName").value);
    // formData.append("classAmount", document.getElementById("classAmoubt").value);
    formData.append("classAmount", document.getElementById("classAmount").value);
    formData.append("classDays", document.getElementById("classDays").value);
    formData.append("eventPrice", document.getElementById("eventPrice").value);
    formData.append("classStartDate", newStartDate);
    formData.append("payment", document.getElementById("payment").value);
    formData.append("amount", document.getElementById("amount").value);
    formData.append("memo", document.getElementById("memo").value);
    formData.append("total", document.getElementById("total").value);
    formData.append("operator", operator);
    formData.append("invoiceID", invoiceID);
    formData.append("token", token);

    fetch(`${url}/appPost/updateOrder`, {
      method: "POST",
      body: formData,
      mode: "cors",
    })
      .then((res) => {
        return res.json()

      }).then(() => {
        window.location.href = `/OrderListView`
      })

    // return window.location.href = `/SingleStudentView/${studentDBID}`
}


const BtnToDel = () => {

  return <>

   <div  style={{"width":"90%"}}>
      <div className="input-group mb-3">
        <div className="input-group-prepend">
          <span
            className="input-group-text alert-info"
            id="validationTooltipUsernamePrepend"
          >
            安全確認
                    </span>

        </div>

        <input
          type="text"
          id="securityCode"
          className="form-control "
          aria-describedby="validationTooltipUsernamePrepend"
          placeholder={`請輸入 ${orderDBID}`}
          onChange={(e) => {
            let value = e.target.value
            console.log(value)

            if (value == orderDBID) {
              document.getElementById("goCancelBtn").disabled = false
            } else {
              document.getElementById("goCancelBtn").disabled = true
            }

          }}
         
          required
        />

        <div  className="btn btn-danger " id="goCancelBtn"  onClick={del}  >刪除 {orderDBID} 訂單資料</div>
      </div>


      </div>

  </>
}


  const addDays = (days) => {
    var dat = new Date(this.valueOf()); // (1)
    dat.setDate(dat.getDate() + days);  // (2)
    return dat
  }

  useEffect(() => {

    getOrderData(orderDBID)
  }, [])





  return (
    <>

      <div className="container vw-100 " style={{ maxWidth: "500px" }}>
        <div className="card bg-info text-white p-2">
          <h5 className="m-1" id="title">  課程訂單檢視</h5>
        </div>
      
        <div className="mt-2">
          <div className="card mt-2 ">
            <div className="card-header alert-info">基本資料區</div>

            <div className="m-1">
              <div className="input-group">
                <div className="input-group-prepend">
                  <span
                    className="input-group-text alert-info"
                    id="validationTooltipUsernamePrepend"
                  >
                    姓名
                      </span>
                </div>
                <div 
                  id="name"
                  className="form-control"
                  aria-describedby="validationTooltipUsernamePrepend"
                />
              </div>
            </div>

            <div className="m-1">
              <div className="input-group">
                <div className="input-group-prepend">
                  <span
                    className="input-group-text alert-info"
                    id="validationTooltipUsernamePrepend"
                  >
                    暱稱
                      </span>
                </div>
                <div

                  id="nickName"
                  className="form-control"
                  aria-describedby="validationTooltipUsernamePrepend"

                />
              </div>
            </div>
            <div className="m-1">
              <div className="input-group">
                <div className="input-group-prepend">
                  <span
                    className="input-group-text alert-info"
                    id="validationTooltipUsernamePrepend"
                  >
                    家長
                      </span>
                </div>
                <div

                  id="parent"
                  className="form-control"
                  aria-describedby="validationTooltipUsernamePrepend"

                />
              </div>
            </div>
          </div>
        </div>
        <div className="card  mt-2 " style={{ width: "100%" }}>
        <div className="card-header alert-warning">訂單資料區</div>
        <div className="m-1 ">
          <div className="input-group">
            <div className="input-group-prepend">
              <span
                className="input-group-text alert-warning"
                id="validationTooltipUsernamePrepend"
              >
                訂單日期
                      </span>
            </div>

            <input

              id="orderDate"
              className="form-control"
              aria-describedby="validationTooltipUsernamePrepend"

            />
          </div>
        </div>



        <div className="m-1 ">
          <div className="input-group">
            <div className="input-group-prepend">
              <span
                className="input-group-text alert-warning"
                id="validationTooltipUsernamePrepend"
              >
                專案代碼
                      </span>
            </div>

            <input type="text"

              id="eventDBID"
              className="form-control"
              aria-describedby="validationTooltipUsernamePrepend"

            />
          </div>
        </div>

        <div className="m-1 ">
          <div className="input-group">
            <div className="input-group-prepend">
              <span
                className="input-group-text alert-warning"
                id="validationTooltipUsernamePrepend"
              >
                專案名稱
                      </span>
            </div>

            <input type="text"

              id="eventName"
              className="form-control"
              aria-describedby="validationTooltipUsernamePrepend"

            />
          </div>
        </div>

        <div className="m-1 ">
          <div className="input-group">
            <div className="input-group-prepend">
              <span
                className="input-group-text alert-warning"
                id="validationTooltipUsernamePrepend"
              >
                訂購數量
                      </span>
            </div>
            <input

              id="amount"
              className="form-control"
              aria-describedby="validationTooltipUsernamePrepend"

            />

          </div>
        </div>

        <div className="m-1 ">
          <div className="input-group">
            <div className="input-group-prepend">
              <span
                className="input-group-text alert-warning"
                id="validationTooltipUsernamePrepend"
              >
                專案堂數
                      </span>
            </div>
            <input

              id="classAmount"
              className="form-control"
              aria-describedby="validationTooltipUsernamePrepend"

            />

          </div>
        </div>


        <div className="m-1 ">
          <div className="input-group">
            <div className="input-group-prepend">
              <span
                className="input-group-text alert-warning"
                id="validationTooltipUsernamePrepend"
              >
                課程天數
                      </span>
            </div>
            <input

              id="classDays"
              className="form-control"
              aria-describedby="validationTooltipUsernamePrepend"

            />

          </div>
        </div>

        <div className="m-1 ">
          <div className="input-group">
            <div className="input-group-prepend">
              <span
                className="input-group-text alert-warning"
                id="validationTooltipUsernamePrepend"
              >
              專案單價
                      </span>
            </div>
            <input

              id="eventPrice"
              className="form-control"
              aria-describedby="validationTooltipUsernamePrepend"

            />

          </div>
        </div>


        <div className="m-1 ">
          <div className="input-group">
            <div className="input-group-prepend">
              <span
                className="input-group-text alert-warning"
                id="validationTooltipUsernamePrepend"
              >
                訂單總金額
                      </span>
            </div>
            <input

              id="total"
              className="form-control"
              aria-describedby="validationTooltipUsernamePrepend"

            />

          </div>
        </div>


        <div className="m-1 ">
          <div className="input-group">
            <div className="input-group-prepend">
              <span
                className="input-group-text alert-warning"
                id="validationTooltipUsernamePrepend"
              >
              發票號碼
                      </span>
            </div>
            <input

              id="invoiceID"
              className="form-control"
              aria-describedby="validationTooltipUsernamePrepend"

            />

          </div>
        </div>


        <div className="m-1 ">
          <div className="input-group">
            <div className="input-group-prepend">
              <span
                className="input-group-text alert-warning"
                id="validationTooltipUsernamePrepend"
              >
                付款方式
                      </span>
            </div>
            <input

              id="payment"
              className="form-control"
              aria-describedby="validationTooltipUsernamePrepend"

            />

          </div>
        </div>










        
          </div>
  
          <div className="card  mt-2 " style={{ width: "100%" }}>
            <div className="card-header alert-danger">課程資料區</div>
            <div className="m-1 ">
              <div className="input-group">
                <div className="input-group-prepend">
                  <span
                    className="input-group-text alert-danger"
                    id="validationTooltipUsernamePrepend"
                  >
                    課程啟用日期
                      </span>
                </div>
            <input

              id="classStartDate"
              className="form-control"
              aria-describedby="validationTooltipUsernamePrepend"

            />
              </div>
            </div>
            {/* <div className="m-1 ">
              <div className="input-group">
                <div className="input-group-prepend">
                  <span
                    className="input-group-text alert-danger"
                    id="validationTooltipUsernamePrepend"
                  >
                    課程展延
                      </span>
                </div>
                <div

              id="classExtend"
              className="form-control"
              aria-describedby="validationTooltipUsernamePrepend"

            />
              </div>
            </div> */}

            <div className="m-1 ">
              <div className="input-group">
                <div className="input-group-prepend">
                  <span
                    className="input-group-text alert-danger"
                    id="validationTooltipUsernamePrepend"
                  >
                    課程到期日
                      </span>
                </div>
                <div 

              id="classEndDate" type="text"
              className="form-control"
              aria-describedby="validationTooltipUsernamePrepend"

            ></div>
              </div>
            </div>
   

            <div className="m-1 ">
              <div className="input-group">
                <div className="input-group-prepend">
                  <span
                    className="input-group-text alert-danger"
                    id="validationTooltipUsernamePrepend"
                  >
                    訂單備註
                      </span>
                </div>
                <input

              id="memo"
              className="form-control"
              aria-describedby="validationTooltipUsernamePrepend"

            />

              </div>
              <div className="mt-1 btn alert-success ml-auto mr-auto " style={{"width":"100%"}}onClick={()=>{
      window.location.href=`/orderExtend/${orderDBID}`
    }}> 請假延長申請</div>
     <div className=" p-2 alert-dark" align="center"><BtnToDel /></div>
            </div>
          </div>
    <div className="m-3">
    <div className="input-group">
    <div className="btn btn-info ml-auto mr-auto" onClick={()=>{
      window.location.href=`/SingleStudentView/${studentDBID}`
    }}> 回{name} 學員資料</div>
    <div className="btn btn-warning ml-auto mr-auto" onClick={handelSummit}>更新訂單資料</div>

    </div> 
   
    </div>
   
          </div>
      

    

      
    </>
  )

};



export default OrderView;