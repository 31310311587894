import React, { useState, useEffect } from "react";
import { useMappedState, useDispatch } from 'redux-react-hook';
import { Link, useParams } from "react-router-dom";
import logo from "../icon/good2swim-logo.png";
import HomeSVG from "../icon/house.svg";
import eject from "../icon/eject.svg";
import studentIcon from "../icon/people.svg";
import saleIcon from "../icon/sale.svg";
import adminIcon from "../icon/admin.svg";
import classIcon from "../icon/swimClass.svg";
import starIcon from "../icon/star.svg";
import pencilIcon from "../icon/pencil.svg";
import personIcon from "../icon/person.svg";
import getDay from "../function/getDay.js"
import checkLogin from "../function/checkLogin.js"
import coachIcon from "../icon/coach.svg"
import report from "../icon/contract.svg"
import fingerTap from "../icon/tap.svg"
import getUrl from "../function/getUrl.js"
const HeaderBar = (props) => {
  const counter = useMappedState(state => state)
  const dispatch = useDispatch()
  const url = getUrl()
  const name = sessionStorage.getItem('name')
  const timecode = sessionStorage.getItem("timeCode")
  const operator = sessionStorage.getItem("staffDBID")
  const [countReport, setCountReport] = useState(0)
  const token = sessionStorage.getItem("token")
  const now = Date.now()
  const version = "V 3.0.1 ";
  const today = getDay(0, "-")
  const level = sessionStorage.getItem("level")
  var imgStyle = {
    width: "30px",
    height: "30px",
  };

  var fontStyle = {
    fontSize: "0.5em",
  };




  let GetDailyTableURL = `/GetDailyTable/${today}`

  let GetWeekTableURL = `/GetWeekTable/${today}`
  useEffect(async () => {
    // console.log('${url}/appGet/countReport?token=${token}',${url}/appGet/countReport?token=${token})
    let data = await fetch(`${url}/appGet/countReport?token=${token}`).then(res => res.json()).then(json => {
      console.log(json[0])
      return json
    })
    try {
      setCountReport(data[0]["count"])
    } catch {
      setCountReport(0)
    }

    console.log(data)
  }, [])

  if (now > timecode || timecode == null) {
    //時間過期
    return (
      <nav className="navbar navbar-light navbar-expand-lg  bg-dark ">
        <div className="mr-auto">
          <Link className="navbar-brand" to="/Login" style={{ textAlign: "center" }}>
            <img src={logo} alt="logo" style={{ weight: "35px", height: "35px" }} />
            {/* <div onClick={() => { dispatch({ type: "INCREMENT" }) }}>{counter.name}</div> */}
          </Link>
        </div>
        <div className="mr-right">
          <Link className="navbar-brand" to="/login" style={{ textAlign: "center" }}>
            <img src={HomeSVG} style={imgStyle} alt="Home" />
            <span className="text-light" style={fontStyle}>
              {" "}
              登入
            </span>
          </Link>
        </div>


      </nav>
    )
  }




  return (

    <>
      <nav className="navbar navbar-light navbar-expand-lg  bg-dark ">

        <div className="mr-auto">
          <div className="navbar-brand btn  btn-dark" onClick={() => { window.location.href = "/" }} style={{ textAlign: "center" }}>
            <img src={HomeSVG} style={imgStyle} alt="Home" />
            <span className="text-light" style={fontStyle}>
              {" "}
              Home
            </span>
          </div>
        </div>

        <div className="mr-auto">
          <div className="navbar-brand btn  btn-dark" onClick={() => { window.location.href = `${GetDailyTableURL}` }} style={{ textAlign: "center" }}>
            <img src={classIcon} style={imgStyle} alt="Home" />
            <span className="text-light" style={fontStyle}>
              {" "}
              日課表
            </span>
          </div>
        </div>


        <button className="navbar-toggler " type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
          <span className="navbar-toggler-icon"></span>
        </button>
        <div className="collapse navbar-collapse " id="navbarSupportedContent">
          <div className="nav-item dropdown mr-auto">
            <button
              className="btn btn-dark dropdown-toggle"
              type="button"
              id="dropdownMenuButton"
              data-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
            >
              <img src={studentIcon} style={imgStyle} alt="PersonAdd" />
              <span className="text-light" style={fontStyle}>
                {" "}
                學員
              </span>
            </button>
            <div className="dropdown-menu " aria-labelledby="dropdownMenuButton">


              <div className="dropdown-item btn  " onClick={() => {
                window.location.href = "/getStudentList/detail"
              }}>
                學員查詢
              </div>


              <div className="dropdown-item btn" onClick={() => { window.location.href = "/getStudentTrialList" }}>
                體驗報名處理
              </div>
              <div className="dropdown-item btn" onClick={() => { window.location.href = "/StudentAdd" }}>
                新增學員
              </div>
              <div className="dropdown-item btn" onClick={() => { window.location.href = "/getStudentListDiffDays" }}>
                60天內到期名單
              </div>
            </div>
          </div>

          <div className="nav-item dropdown mr-auto">
            <button
              className="nav-link btn btn-dark dropdown-toggle"
              type="button"
              id="dropdownMenuButton"
              data-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
            >
              <img src={classIcon} style={imgStyle} alt="PersonAdd" />
              <span className="text-light" style={fontStyle}>
                {" "}
                課程
              </span>
            </button>
            <div className=" dropdown-menu" aria-labelledby="dropdownMenuButton">
              <div className="dropdown-item btn" onClick={() => { window.location.href = "/getClassList/detail" }}>
                課程查詢
              </div>
              <div className="dropdown-item btn" onClick={() => { window.location.href = `${GetDailyTableURL}` }}>
                日課表
              </div>
              <div className="dropdown-item btn  " onClick={() => {
                window.location.href = GetWeekTableURL
              }}>
                周課表
              </div>
              <div className="dropdown-item btn" onClick={() => { window.location.href = `/classAdd/${today}` }}>
                新增單堂
              </div>
              <div className="dropdown-item btn" onClick={() => { window.location.href = `/classAddBatch` }}>
                批次新增單堂
              </div>
              <div className="dropdown-item btn" onClick={() => { window.location.href = "/GetClassTemplateList" }}>
                周課表模板
              </div>
              <div className="dropdown-item btn" onClick={() => { window.location.href = "/GetClassTemplateListForTerms" }}>
                短期寒暑周課表模板
              </div>
              <div className="dropdown-item btn" onClick={() => { window.location.href = "/GetDailySignTable" }}>
                線上簽到
              </div>
            </div>
          </div>


          <div className="dropdown mr-auto">
            <button
              className="btn btn-dark dropdown-toggle"
              type="button"
              id="dropdownMenuButton"
              data-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
            >
              <img src={coachIcon} style={imgStyle} alt="PersonAdd" />
              <span className="text-light" style={fontStyle}>
                {" "}
                教練
              </span>
            </button>
            <div className="dropdown-menu" aria-labelledby="dropdownMenuButton">
              <div className="dropdown-item btn" onClick={() => { window.location.href = `/coachClassListThisMonth/${operator}` }}>
                教練授課 授課一覽表
              </div>
              <div className="dropdown-item btn" onClick={() => { window.location.href = "/getClassList/classProcess" }}>
                學習進度
              </div>
            </div>
          </div>

          <div className="dropdown mr-auto">
            <button
              className="btn btn-dark dropdown-toggle"
              type="button"
              id="dropdownMenuButton"
              data-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
            >
              <img src={saleIcon} style={imgStyle} alt="PersonAdd" />
              <span className="text-light" style={fontStyle}>
                {" "}
                銷售
              </span>
            </button>
            <div className="dropdown-menu" aria-labelledby="dropdownMenuButton">
              <div className="dropdown-item btn" onClick={() => { window.location.href = "/monthlyReport" }}>
                月銷售報表
              </div>
              <div className="dropdown-item btn" onClick={() => { window.location.href = "/OrderListView" }}>
                課程訂單資料列表
              </div>
              <div className="dropdown-item btn" onClick={() => { window.location.href = "/ProductListView" }}>
                商品銷售資料列表
              </div>
              <div className="dropdown-item btn" onClick={() => { window.location.href = "/ProductOrderAdd" }}>
                商品銷售
              </div>
            </div>
          </div>

          <div className="dropdown mr-auto">
            <button
              className="btn btn-dark dropdown-toggle"
              type="button"
              id="dropdownMenuButton"
              data-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
            >
              <img src={adminIcon} style={imgStyle} alt="PersonAdd" />
              <span className="text-light" style={fontStyle}>
                {" "}
                店務
              </span>
            </button>
            <div className="dropdown-menu" aria-labelledby="dropdownMenuButton">

              <div className="dropdown-item btn" onClick={() => { window.location.href = "/staffListView" }}>
                員工維護
              </div>
              <div className="dropdown-item btn" onClick={() => { window.location.href = "/staffAdd" }}>
                新增員工
              </div>

            </div>
          </div>



          {/* <div className="mr-auto">
            <div className=" btn btn-dark " onClick={()=>{window.location.href="/GetDailySignTable"}}>
            <img src={fingerTap} width="25pt" alt="eject" /><span className="text-light" style={fontStyle}> 線上簽到</span>
            </div>
            
          </div> */}
          <div className="mr-auto">
            <div className=" btn btn-dark " onClick={() => { window.location.href = "https://good2swim-os3-signpad-oi3as7ovga-de.a.run.app" }}>
              <img src={fingerTap} width="25pt" alt="eject" /><span className="text-light" style={fontStyle}> 簽到</span>
            </div>

          </div>



          <div className="dropdown mr-auto">
            <button
              className="btn btn-dark dropdown-toggle"
              type="button"
              id="dropdownMenuButton"
              data-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
            >
              <img src={report} style={imgStyle} alt="PersonAdd" />
              <span className="text-light" style={fontStyle}>
                {" "}
                個案 <span className="badge badge-pill badge-danger">{countReport}</span>
              </span>
            </button>
            <div className="dropdown-menu" aria-labelledby="dropdownMenuButton">

              <div className="dropdown-item btn" onClick={() => { window.location.href = "/GetReportList" }}>
                個案核備列表
              </div>
              <div className="dropdown-item btn" onClick={() => { window.location.href = "/reportAdd" }}>
                個案核備申請
              </div>
            </div>
          </div>

          <div className="mr-auto">
            <div className=" btn btn-dark" onClick={() => { window.location.href = "https://member.good2swim.com/trial" }}>
              <img src={starIcon} style={imgStyle} alt="eject" />
              <span className="text-light" style={fontStyle}>
                體驗
              </span>
            </div>
          </div>

          <div className="dropdown mr-auto">
            <button
              className="btn btn-dark dropdown-toggle"
              type="button"
              id="dropdownMenuButton"
              data-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
            >
              <img src={personIcon} style={imgStyle} alt="Home" />
              <span className=" btn alert-light" >
                {name}
              </span>
            </button>
            <div className="dropdown-menu" aria-labelledby="dropdownMenuButton">
              <div className="dropdown-item btn" onClick={() => { window.location.href = `/staffEdit/${operator}` }}>
                個人資料維護
              </div>
              <div className="dropdown-item btn" onClick={() => { window.location.href = `/sqlShow` }}>
                Squery
              </div>

              <div className="dropdown-item btn" onClick={() => { window.location.href = "/logout" }}>
                登出
              </div>
            </div>
          </div>
        </div>
      </nav>
    </>
  );
};

export default HeaderBar;
