import React, {
  useState,
  useEffect,
  useCallback,
  updateState,
  useReducer,
  useContext,
} from "react";
import getUrl from "../function/getUrl.js";
import FunBarClass from "./funBarClass.js";
import getDay from "../function/getDay.js";
import dateDiff from "../function/dateDiff.js"; //日期差
import checked from "../icon/check.png";
import canceled from "../icon/multiply.png";
const url = getUrl();
const token = sessionStorage.getItem("token");
//課程畫面元件
const ClassDataViewComponent = (props) => {
  const classDBID = props.classDBID;
  const [classData, setClassData] = useState({});

  const fetCoachData = () => {
    return fetch(`${url}/appGet/getStaffData?token=${token}`)
      .then((res) => {
        return res.json();
      })
      .then((json) => {
        return json;
      });
  };


  function todayAdd14Days(){
      
    let tempDate=new Date()
    let classEndDate=new Date(tempDate.setDate(tempDate.getDate()  + 14))


    let tempMonth=parseInt(classEndDate.getMonth(),"10")+1 
    let tempDate2=classEndDate.getDate()
    if (classEndDate.getDate() <10){
      tempDate2="0"+tempDate2
    }
    let result=classEndDate.getFullYear()+"-0"+tempMonth+"-"+tempDate2
    if (tempMonth > 10){
      result= classEndDate.getFullYear()+"-"+tempMonth+"-"+tempDate2        }
    // console.log(todayAdd14Days)
    return result

}

  //查詢課程的星期
  const getWeekDay = (classDate) => {
    const dateWeek = new Date(classDate).getDay();
    const day_list = ["日", "一", "二", "三", "四", "五", "六"];
    return day_list[dateWeek];
  };

  useEffect(async () => {
    const staffData = await fetCoachData();

    fetch(`${url}/appGet/singleClassView?classDBID=${classDBID}&token=${token}`)
      .then((res) => {
        return res.json();
      })
      .then((data) => {
        //處理星期顯示
        console.log(data)
        sessionStorage.setItem('thisClassIsELP', false);
        if (data.age=="ELP"){
          sessionStorage.setItem('thisClassIsELP', true);
        }
        
        const dateWeek = new Date(data.classDate).getDay();
        const day_list = ["日", "一", "二", "三", "四", "五", "六"];
        data.week = day_list[dateWeek];
        data["webAppShow"] = "無法劃位"
        if (data["webApp"] == 'true' && data["available"] == 'true'  && (data["age"] != "備用")) {
          data["webAppShow"] = "開放自行劃位"
        }

        if (data["classDate"] >todayAdd14Days()){
          data["webAppShow"] = "超過14天不開放劃位"
        }

        //課程超過5日封存 不開放修改
        const diffDays=dateDiff(getDay(0,"-"),data["classDate"])
        // if (diffDays >5){
        //   if (sessionStorage.getItem('level')==5){
        //     if(!window.confirm('管理者是否轉跳封存')){
        //       return 
        //     }
        //   }
          
        //   window.location.href=`/singleClassViewReadOnly/${classDBID}`
        // }
        staffData.map((item, index) => {

          if (item.staffDBID == data.coachID) {
            data.coach = item.name;
          }
          if (item.staffDBID == data.assistantID) {
            data.assistant = item.name;
          }
        });
        // if (data.webApp == "true") {
        //   data.webApp = "Yes";
        // } else {
        //   data.webApp = "No";
        // }

        // if (data.available == "true") {
        //   data.available = "Yes";
        // } else {
        //   data.available = "No";
        // }

        // if (data.trial == "true") {
        //   data.trial = "Yes";
        // } else {
        //   data.trial = "No";
        // }


        setClassData(data);
      });
  }, []);


 //圖像化 顯示是否開課/app劃位
  function ShowChecedk(props){
    if (props.flag=="true"){
      return <img src={checked} width={"20px"}/>
    }else{
      return  <img src={canceled} width={"20px"}/>
    }
  }
  return (
    <>
      <FunBarClass
        classDBID={classData["classDBID"]}
        classDate={classData["classDate"]}
      />
      <div className="card  border-1  w-80">
        <h5 className="text-muted card-header mb-1 ">課程資訊</h5>
        <div className="card p-1 m-2 alert-info">
          <div className="row">
            <div className="col  ">
              <div className="card-body rounded border-0 ">
                <h6 className="card-title">課桯基本資料</h6>

                <ul className="list-group list-group-flush text-left ">
                  <li className="list-group-item">
                    日期：{classData["classDate"]}
                  </li>
                  <li className="list-group-item">星期：{classData["week"]}</li>
                  <li className="list-group-item">
                    時間：{classData["classTime"]}
                  </li>
                  <li className="list-group-item" onClick={()=>{
                      window.location.href=`/classEdit/${classDBID}`
                    }}>
                    開課：<ShowChecedk flag={classData["available"]}/>
                    <br />
                    <small className="text-muted" >對外是否開課</small>
                  </li>
                  <li className="list-group-item">
                    APP：<ShowChecedk flag={classData["webApp"]}/><br />
                    <small className="text-muted" >為是否顯示於學員App上</small>
                  </li>
                </ul>
              </div>
            </div>

            <div className="col ">
              <div className="card-body rounded border-0 ">
                <h6 className="card-title">課程教學</h6>

                <ul className="list-group list-group-flush text-left ">
                  <li className="list-group-item">班別：{classData["age"]}</li>
                  <li className="list-group-item">
                    主題：{classData["classProcess"]}
                  </li>
                  <li className="list-group-item">
                    教練：{classData["coach"]}
                  </li>
                  <li className="list-group-item">
                    助教：{classData["assistant"]}
                  </li>
                  {/* <li className="list-group-item">
                    體驗：{classData["trial"]}
                    <br />
                    <small className="text-muted">本堂是否開放體驗</small>
                  </li> */}
                </ul>
              </div>
            </div>

            <div className="col ">
              <div className="card-body  rounded border-0 ">
                <h6 className="card-title">人數狀況</h6>

                <ul className="list-group list-group-flush text-left ">
                  <li className="list-group-item">
                    上限：{classData["limit"]}
                  </li>
                  <li className="list-group-item">
                    劃位：{classData["booking"]}
                  </li>
                  <li className="list-group-item">
                    候補：{classData["backup"]}
                  </li>
                  <li className="list-group-item">
                    取消：{classData["cancel"]}
                  </li>
                  <li className="list-group-item">完課：{classData["done"]}</li>
                </ul>
              </div>
            </div>
          </div>

        </div>
        <div className="card p-1 m-2 alert-info ">
          <div className="row">
            <div className="col mt-1">
              <span className="ml-4 p-1">學員APP 狀態：</span>
              <span className=" alert bg-warning p-1 text-dark">{classData.webAppShow}</span>

              <div className="row">
                <small className="ml-5 ">學員APP中 無法劃位條件（任一）： </small>
                <small className="ml-3 ">App : false  </small>
                <small className="ml-3 ">開課 : false  </small>
                <small className="ml-3 ">班別 : 備用  </small>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ClassDataViewComponent;
