
import React, {
  useState,
  useEffect,
} from "react";
import getUrl from "../function/getUrl.js"
import FunBarClass from "./funBarClass.js";
import { Link, useParams } from "react-router-dom";
import getDay from "../function/getDay.js"
import dateDiff from "../function/dateDiff.js"
import checkLogin from "../function/checkLogin.js"
//課程畫面元件
const ClassAdd = () => {
checkLogin()
  let param = useParams(); //功能項目
  let classDateNew = param.classDate;
  let classDBID=null
  const [staffList,setStaffList]=useState([])
  const url = getUrl()
  const today=getDay(0,"-")
  const [coachIDState,setCoachIDState]=useState(null)
  const [classDate, setClassDate] = useState(null)
  const [week, setWeek] = useState(null)
  const day_list = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat']
  const [allDayClassData,setAllDayClassData]=useState([])
  const [newClassTime,setClassTime]=useState(null)
  const token=sessionStorage.getItem('token')
  const [classProcessList, setClassProcessList] = useState([])

  function getClassList(getDate){
    
    return fetch(`${url}/appGet/getDailyClassTableAmount?date=${getDate}&token=${token}`).then((res)=>{
        return res.json();
    }).then(json=>{
      
        json.sort((a,b)=>{

            var nameA = a.classTime; 
            var nameB = b.classTime; 
            if (nameA > nameB) {
              return 1;
            }
            if (nameA < nameB) {
              return -1;
            }
          

          return 0;
          })
          
       console.log('allDayClassData',json)
       let classTimeData=new Set()
       json.map(item=>{
         console.log(item.classTime)
        classTimeData.add(item.classTime)
       })
       
       return json
    })
  }

  const fetClassProcessList = () => {
    return fetch(`${url}/appGet/getClassProcessList?token=${token}`).then((res) => {
      return res.json()
    }).then(json => {
      console.log(json)
      return json
    })
  }
  
  const fetCoachData = () => {
    return fetch(`${url}/appGet/getStaffData?token=${token}`).then((res) => {
      return res.json()
    }).then(json => {
      
      return json


    })
  }

  const getFetchData=async (date)=>{
    let staffTemp=await fetCoachData()
    setStaffList(staffTemp)
    let allDayClassDataTemp= await getClassList(date)
   
    setAllDayClassData(allDayClassDataTemp)

    const classProcessListData = await fetClassProcessList()
    setClassProcessList(classProcessListData)
  }

  useEffect(()=>{
    document.getElementById("classDate").value = param.classDate
    document.getElementById('saveButton').disabled=true
  
  

    getFetchData(today)
    const day_list = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];
  let dateWeek = new Date(classDateNew).getDay()
  var weekValue = day_list[dateWeek]
  setWeek(weekValue)
  
  console.log('allDayClassData',allDayClassData)
  },[])
  

  
 

 



  

  function getSelect(e) {
    let selected = [];
    let optionFollow = e;

    for (let i = 0; i < optionFollow.length; i++) {
      if (optionFollow[i].selected) {
        selected.push(optionFollow[i].value);
      }
    }
    return selected;
  }

  function handelSummit(e) {
    let operator=sessionStorage.getItem("staffDBID")

    var formData = new FormData();

    //確認是否有重複的課程
    if(allDayClassData.some(item=>{
      return item.classTime==document.getElementById("classTime").value
    })){
      alert('開課時間重複')
      setClassTime('')
      return 
    }
 
    
    formData.append("classTime", document.getElementById("classTime").value);
    formData.append("age", document.getElementById("age").value);
    formData.append("limit", document.getElementById("limit").value);
    formData.append("coachID", document.getElementById("coachID").value);
    formData.append("assistantID", document.getElementById("assistantID").value);
    formData.append("classDate", document.getElementById("classDate").value);
    formData.append("classProcess", document.getElementById("classProcess").value);
    formData.append("available", document.getElementById("available").value);
    formData.append("webApp", document.getElementById("webApp").value);
    formData.append("trial", document.getElementById("trial").value);
    formData.append("operator", operator);
    formData.append("token", sessionStorage.getItem("token"));
    // alert(document.getElementById("classTime").value)

    fetch(`${url}/appPost/addNewClass`, {
      method: "POST",
      body: formData,
      mode: "cors",
    })
      .then((res) => {
        return res.json()

      }).then(()=>{
        window.location.href = `/GetDailyTable/${document.getElementById("classDate").value}`
      }).catch(err=>{

        console.log('錯誤:', err);
        alert('伺服器回應建立失敗')
        window.location.href = `/GetDailyTable/${document.getElementById("classDate").value}`
      })

    
  }




  return (
    <>
 
      <div className="container vw-100 " style={{ maxWidth: "500px" }}>

        <div className="card bg-info text-white p-2">
          <h5 className="m-1">課程編號:{classDBID} - {classDate} 資料維護</h5>
        </div>

        <div className="mt-2">
          <div className="card mt-2 ">
            <div className="card-header alert-info">課程基本資料區</div>
            <div className="card-body ">


              <div className="m-1">
                <div className="input-group">
                  <div className="input-group-prepend">
                    <span
                      className="input-group-text alert-info"
                      id="validationTooltipUsernamePrepend"
                    >
                      開課日期
                      </span>
                  </div>
                  <input
                    type="date"
                    id="classDate"
                    className="form-control"
                    value={classDate}
                    onChange={(e) => {
                      let value = e.target.value
                      getFetchData(value)
                      setClassDate(value)
                      let dateWeek = new Date(value).getDay()
                      var weekValue = day_list[dateWeek]
                      setWeek(weekValue)
                      setClassTime('')
                      document.getElementById('saveButton').disabled=true
                    }}
                    aria-describedby="validationTooltipUsernamePrepend"
                    required
                  />
                  <div className="input-group-prepend"><span className="input-group-text alert-info"
                    id="validationTooltipUsernamePrepend">星期：{week}</span></div>
                </div>
              </div>

              <div className="m-1">
                <div className="input-group">
                  <div className="input-group-prepend">
                    <span
                      className="input-group-text alert-info"
                      id="validationTooltipUsernamePrepend"
                    >
                      開課時間
                      </span>
                  </div>
                  <input
                    type="text"
                    id="classTime"
                    className="form-control"
                    aria-describedby="validationTooltipUsernamePrepend"
                    placeholder="請填入4位數開課時間"
                    value={newClassTime}
                    onChange={(e)=>{
                     
                      //確認是否有重複的課程
                      if(allDayClassData.some(item=>{
                        return item.classTime==e.target.value
                      })){
                        alert('本開課時間重複')
                        setClassTime('')
                        document.getElementById('saveButton').disabled=true
                        return 
                      }
                     
                      setClassTime(e.target.value)
                       
                      if (e.target.value.length !== 4){
                        document.getElementById('saveButton').disabled=true
                        return 
                      }
                      document.getElementById('saveButton').disabled=false
                    }}
                    required
                  />
                </div>
              </div>
                <div className="text-muted ml-3 small-text">本日已開課：{allDayClassData.map(item=>{
                        return item.classTime+" "
                      })}</div>
              <div className="m-1">
                <div className="input-group">
                  <div className="input-group-prepend">
                    <span
                      className="input-group-text alert-info"
                      id="validationTooltipUsernamePrepend"
                    >
                      班別年紀
                      </span>
                  </div>
                  <select id="age" className="custom-select" required>
                  <option value="3-7Y">3-7Y</option>
                    <option value="1-2Y">1-2Y</option>
                    <option value="2-3Y">2-3Y</option>
                    <option value="2M">2M</option>
                    <option value="3-6M">3-6M</option>
                    <option value="6-12M">6-12M</option>
                    <option value="ELP">ELP</option>
                    <option value="備用">備用</option>
                    <option value="暑期班">暑期班</option>
                    <option value="水中攝影">水中攝影</option>
                    <option value="自由練習">自由練習</option>
                    <option value="體驗">體驗</option>
                  </select>
                </div>
              </div>

              <div className="m-1">
                <div className="input-group">
                  <div className="input-group-prepend">
                    <span
                      className="input-group-text alert-info"
                      id="validationTooltipUsernamePrepend"
                    >
                      人數上限
                      </span>
                  </div>
                  <select id="limit" className="custom-select" required>
                  <option value="7">7</option>
                  <option value="6">6</option>
                  <option value="5">5</option>
                  <option value="4">4</option>
                  <option value="3">3</option>
                    <option value="2">2</option>
                    <option value="1">1</option>
                  </select>
                </div>
              </div>


              <div className="m-1">
                <div className="input-group">
                  <div className="input-group-prepend">
                    <span
                      className="input-group-text alert-info"
                      id="validationTooltipUsernamePrepend"
                    >
                      開放APP選課
                      </span>
                  </div>

                  <select id="webApp" className="custom-select" required>
                    <option value="true">Yes</option>
                    <option value="false" >No</option>
                  </select>
                </div>
              </div>
              <div className="m-1 ">
                <div className="input-group">
                  <div className="input-group-prepend">
                    <span
                      className="input-group-text alert-info"
                      id="validationTooltipUsernamePrepend"
                    >
                      是否開課
                      </span>
                  </div>

                  <select id="available" className="custom-select" required>
                    <option value="true"selected>Yes</option>
                    <option value="false" >No</option>
                  </select>
                </div>
              </div>

              <div className="m-1 ">
                <div className="input-group">
                  <div className="input-group-prepend">
                    <span
                      className="input-group-text alert-info"
                      id="validationTooltipUsernamePrepend"
                    >
                      是否開放體驗
                      </span>
                  </div>

                  <select id="trial" className="custom-select" required disabled>
                  <option value="true">Yes</option>
                  <option value="false" >No</option>
                    
                   
                  </select>
                </div>
              </div>

            </div>
          </div>

          <div className="card mt-2 ">
            <div className="card-header alert-primary">授課資料區</div>
            <div className="card-body">
              <div className="m-1">
                <div className="input-group">
                  <div className="input-group-prepend">
                    <span
                      className="input-group-text alert-primary"
                      id="validationTooltipUsernamePrepend"
                    >
                      教練姓名
                      </span>
                  </div>
                  <select id="coachID" className="custom-select" required onChange={(e)=>{
                    setCoachIDState(e.target.value)
                  }} required>
                     <option value="">請選擇</option>
                    {staffList.map(item=>{
                      let dayDiff = dateDiff(classDate, today) // 日期差
                      if (dayDiff > 0){
                        if(item.coach=="true" && item.alive=="true"){
                          return  <option value={item.staffDBID}>{item.name}</option>
                        }
                      }else{
                        //發生在過去的 修改不限定 是否仍在職
                        if(item.coach=="true" ){
                          return  <option value={item.staffDBID}>{item.name}</option>
                        }
                      }
                     
                    })}
                
             
                  </select>
                </div>
              </div>
              <div className="m-1">
                <div className="input-group">
                  <div className="input-group-prepend">
                    <span
                      className="input-group-text alert-primary"
                      id="validationTooltipUsernamePrepend"
                    >
                      助教
                      </span>
                  </div>
                  <select id="assistantID" className="custom-select" required>
                  <option value="">請選擇</option>
                    {staffList.map(item=>{
               
                     let dayDiff = dateDiff(classDate, today) // 日期差
                     if (coachIDState!=item.staffDBID){
                      if (dayDiff > 0){
                        if(item.coach=="true" && item.alive=="true"){
                          return  <option value={item.staffDBID}>{item.name}</option>
                        }
                      }else{
                        //發生在過去的 修改不限定 是否仍在職
                        if(item.coach=="true" ){
                          return  <option value={item.staffDBID}>{item.name}</option>
                        }
                      }
                     }
                    
                    
                    })}
                
             
                  </select>
                </div>
              </div>

              <div className="m-1">
                <div className="input-group">
                  <div className="input-group-prepend">
                    <span
                      className="input-group-text alert-primary"
                      id="validationTooltipUsernamePrepend"
                    >
                      課程主題
                      </span>
                  </div>
                  <select
                    
                    id="classProcess"
                    className="form-control"
                    aria-describedby="validationTooltipUsernamePrepend"
                    required
                  >
                  <option>請選擇</option>
                  
                  {classProcessList.map(item=>{
                    console.log(item.classProcess)
                    return <option>{item.classProcess}</option>
                  })}
               </select>
                </div>
              </div>


              </div>

            </div>
            <div className="card  mt-2 p-1" style={{ width: "100%" }}>
                <button
                  type="button"
                  onClick={handelSummit}
                  className="btn btn-danger "
                  id="saveButton"
                >
                  建立
                </button>
                <Link to={`/SingleClassView/${classDBID}`}>
                  <div
                    className="btn alert-dark mt-1" style={{ width: "100%" }}
                  >
                    取消
                  </div>
                </Link>
              </div>
         
        </div>
      </div>
    </>
  )

};


export default ClassAdd;