import React, {
  useState,
  useEffect,
} from "react";

import getDay from "../function/getDay";
import getUrl from "../function/getUrl";

import checkLogin from "../function/checkLogin.js";


const GetOverNightClassRecord = (props) => {
  const url = getUrl();
  const token = sessionStorage.getItem("token");
  const [classData,setClassData]=useState(null)
  if (!checkLogin()) {
    window.location.href = "/logout";
  }
  var day_list = ['日', '一', '二', '三', '四', '五', '六'];

  
  const getBackupToBooking = async () => {
    let result = await fetch(`${url}/appGet/getOverNightClassRecord?token=${token}`)
      .then((res) => {
        return res.json();
      }).then(result=>{

        let classArray=[]
    for (let x in result){
     
      let single=<tr className="text-center" onClick={()=>{window.location.href = `/SingleClassView/${result[x].classDBID}`}}>
     
      <td>{result[x].classDate}</td>
      <td>{result[x].classTime}</td>
      <td>{result[x].name}</td>
      <td>{result[x].studentState}</td>
     
    </tr>

      classArray.push(single)
    }

    return classArray
        
      })
      console.log(result)
      setClassData(result)
  };
 

  useEffect(() => {
   getBackupToBooking()

  },[])

  if (classData== null || classData== ""){
    return null
  }
  return (
    <>
      <div className="card mt-2" style={{ width: "18rem;" }}>
        <div className="card-body rounded" style={{"backgroundColor":"red"}}>
          <h5 className="card-title text-white">逾期【劃位】未確認完課/缺課 即時資訊</h5>
          <div className="card-text alert-light rounded p-2">
            <table className="table ">
              <thead>
                <tr className="text-center">
                  <th scope="col">課程日期</th>
                  <th scope="col">課程時間</th>
                  <th scope="col">學生</th>
                  <th scope="col">狀態</th>
                </tr>
              </thead>
              <tbody>
                {classData}

              </tbody>
            </table>
          </div>
        </div>
      </div>


    </>
  );
};

export default GetOverNightClassRecord;
