import React, { useState, useEffect } from 'react';
import searchIcon from '../icon/search.svg'
import getUrl from '../function/getUrl'
import checkLogin from "../function/checkLogin.js"
import reloadIcon from '../icon/reload.svg'
import pencilIcon from "../icon/pencil.svg";
import checkLevel from "../function/checkLevel.js"
const url = getUrl()
const token = sessionStorage.getItem("token")
const operator = sessionStorage.getItem("staffDBID")
const level=parseInt(sessionStorage.getItem("level"),"10")
var imgStyle = {
    width: '20px',
    height: '20px',
};

const StaffListView = (props) => {
    checkLogin()
    checkLevel()
    const [staffList, setStaffList] = useState([])


    function handleSearch(e) {
        restStaffList()
        let name=document.getElementById("name").value

        const searchResult=()=>{
            return new Promise((res)=>{
                let newList=staffList.filter(item=>{
                    return item.name.indexOf(name)!=-1
                })
                console.log("newList",newList)
                res(newList)
            })
        }
        
        const setList= async ()=>{
            let data=await searchResult()
            console.log("data",data)
            setStaffList(data)
        }
       
        setList()
    }

    function getStaffData(){
        return fetch(`${url}/appGet/getStaffData?token=${token}`).then((res) => {
            return res.json()
        }).catch((err) => {
            console.log(err)
        })
    }
    const restStaffList=()=>{
        let data=JSON.parse(sessionStorage.getItem('staffList'))
        setStaffList(data)
    }

    const goGetData=async ()=>{
        let data=await getStaffData()
        setStaffList(data)
        sessionStorage.setItem("staffList",JSON.stringify(data))
    }

    useEffect(() => {
        
       
        goGetData()
    }, [])
    return (
        <>
            <div className="card alert-info p-2">
                <div className="container" style={{ width: "500px" }}>
                    <h5 className="m-1">員工資料維護</h5>

                    <div className="card-body">
                        <div className="input-group mb-3">
                            <div className="input-group-prepend">
                                <span className="input-group-text" id="basic-addon1"> <img src={searchIcon} style={imgStyle} alt="search" /> Search</span>
                            </div>
                            <input type="text" id="name" className="form-control" placeholder="員工姓名" aria-label="Username" aria-describedby="basic-addon1"  onChange={restStaffList}/>

                        </div>
                        <div className="w-100 container ">
                            <button className="btn btn-info" onClick={handleSearch} style={{ width: "100%" }} ><img src={reloadIcon} style={imgStyle} /> 送出</button>
                        </div>
                      

                    </div>
                </div>

            </div>
            <div className="mt-5"  >
                <table className="table" >

                    <thead>
                        <tr>
                            <th style={{ width: "10%" }}>編號 </th>
                            <th style={{ width: "20%" }}>姓名</th>
                            <th style={{ width: "15%" }}>暱稱</th>
                            <th style={{ width: "15%" }}>職稱</th>
                            <th style={{ width: "10%" }}>授課狀態</th>
                            <th style={{ width: "20%" }}>手機</th>
                            <th style={{ width: "20%" }}>在職</th>

                        </tr>
                    </thead>
                    <tbody>
                        {
                            staffList.map((item, index) => {
                                let coach = "可授課"
                                if (item['coach'] != "true") {
                                    coach = "- -"
                                }

                                let alive = "在職"
                                if (item['alive'] != "true") {
                                    alive = "離職"
                                }
                                

                                

                                if (item.alive != "true" && level==1 ){
                                  
                                    return null
                                }


                                if (parseInt(item.level,"10") > level ){
                                    console.log('here')
                                    return null
                                }
                                return (
                                    <tr className="text-muted" onClick={
                                        ()=>{
                                            window.location.href=`/staffEdit/${item['staffDBID']}`
                                        }
                                    }>
                                        <td>{item['staffDBID']} </td>
                                        <td>{item['name']}</td>
                                        <td>{item['nickName']}</td>
                                        <td>{item['title']}</td>
                                        <td>{coach}</td>
                                        <td>{item['phone']}</td>
                                        <td>{alive}</td>


                                    </tr>
                                )
                            })
                        }
                    </tbody>
                </table>
            </div>
        </>
    )
}

export default StaffListView;