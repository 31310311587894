
import React, {
  useState,
  useEffect,
  useCallback,
  updateState,
  useReducer,
  useContext,
} from "react";
import getUrl from "../function/getUrl.js"
import FunBarClass from "./funBarClass.js";
import { Link, useParams } from "react-router-dom";
import getDay from "../function/getDay.js"
import dateDiff from "../function/dateDiff.js"
import checkLogin from "../function/checkLogin.js"
//課程畫面元件
const ClassEdit = () => {
  checkLogin()
  let param = useParams(); //功能項目
  let classDBID = param.classDBID;
  const [classData, setClassData] = useState({})
  const [classDate, setClassDate] = useState(null)
  const [classProcessList, setClassProcessList] = useState([])
  const [week, setWeek] = useState(null)
  const day_list = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];
  const [staffList, setStaffList] = useState([])
  const [coachIDState,setCoachIDState]=useState(null)
  const url = getUrl()
  const today=getDay(0,"-")
  const token=sessionStorage.getItem("token")
  useEffect(() => {
    
    const fetchClassData = (classDBID) => {
      return fetch(`${url}/appGet/singleClassView?classDBID=${classDBID}&token=${token}`)
        .then((res) => {
          return res.json();
        })
        .then((json) => {

          return json

        })
    }

    const fetCoachData = () => {
      return fetch(`${url}/appGet/getStaffData?token=${token}`).then((res) => {
        return res.json()
      }).then(json => {
        return json
      })
    }



    const fetClassProcessList = () => {
      return fetch(`${url}/appGet/getClassProcessList?token=${token}`).then((res) => {
        return res.json()
      }).then(json => {
        console.log(json)
        return json
      })
    }

    const fetchData = async () => {
      const data = await fetchClassData(classDBID)
      const staffData = await fetCoachData()
      const classProcessListData = await fetClassProcessList()
      console.log(data)
      setClassData(data)
      setClassDate(data.classDate)
      setStaffList(staffData)
      setClassProcessList(classProcessListData)
      
      
      let dateWeek = new Date(classDate).getDay()
      var weekValue = day_list[dateWeek]
      setWeek(weekValue)
      document.getElementById("age").value = data.age
      document.getElementById("limit").value = data.limit
      document.getElementById("classTime").value = data.classTime
      document.getElementById("coachID").value = data.coachID
      document.getElementById("assistantID").value = data.assistantID
      document.getElementById("classProcess").value = data.classProcess
      console.log(data.webApp)
      //處理 App選課
      let webAppOption = document.getElementById("webApp").options;
      if (data.webApp == "true") {
        for (let y in webAppOption) {

          if (webAppOption[y].value == "true") {
            webAppOption[y].selected = true;
          }
        }

      }
      ///處理 是否開課
      let availableOption = document.getElementById("available").options;
      console.log("data.available", data.available)
      if (data.available == "true") {
        for (let y in availableOption) {

          if (availableOption[y].value == "true") {
            availableOption[y].selected = true;
          }
        }

      }
      ///處理 是否體驗
      let trialOption = document.getElementById("trial").options;
      console.log("data.available", data.available)
      if (data.trial == "true") {
        for (let y in trialOption) {

          if (trialOption[y].value == "true") {

            trialOption[y].selected = true;
          }
        }

      }

      //處理教練清單
      let coachOption = document.getElementById("coachID").options;
      
    
        for (let y in coachOption) {

          if (coachOption[y].value == data.coachID) {

            coachOption[y].selected = true;
          }
        }

        //處理助教清單
      let assistantOption = document.getElementById("assistantID").options;
      console.log("data.assistant",data.assistant)
    
      for (let y in assistantOption) {

        if (assistantOption[y].value == data.assistantID) {

          assistantOption[y].selected = true;
        }
      }

        //處理助教清單
        let classProcessOption = document.getElementById("classProcess").options;
        console.log("data.classProcessOption",data.classProcess)
        if (data.classProcess==""){
          data.classProcess="請選擇"
        }
        for (let y in classProcessOption) {
  
          if (classProcessOption[y].value == data.classProcess) {
  
            classProcessOption[y].selected = true;
          }
        }
      


    };

    fetchData()



  }, [])

  function getSelect(e) {
    let selected = [];
    let optionFollow = e;

    for (let i = 0; i < optionFollow.length; i++) {
      if (optionFollow[i].selected) {
        selected.push(optionFollow[i].value);
      }
    }
    return selected;
  }

  function handelSummit(e) {
    let operator="測試"
    var formData = new FormData();
    formData.append("classDBID", classDBID);
    formData.append("age", document.getElementById("age").value);
    formData.append("limit", document.getElementById("limit").value);
    formData.append("classTime", document.getElementById("classTime").value);
    formData.append("coachID", document.getElementById("coachID").value);
    formData.append("assistantID", document.getElementById("assistantID").value);
    formData.append("classDate", document.getElementById("classDate").value);
    formData.append("classProcess", document.getElementById("classProcess").value);
    formData.append("available", document.getElementById("available").value);
    formData.append("webApp", document.getElementById("webApp").value);
    formData.append("trial", document.getElementById("trial").value);
    formData.append("operator", operator);
    formData.append("token",token)
    fetch(`${url}/appPost/updateClass`, {
      method: "POST",
      body: formData,
      mode: "cors",
    })
      .then((res) => {
        return res.json()

      }).then(()=>{
        window.location.href = `/SingleClassView/${classDBID}`
      })

    // return window.location.href = `/SingleStudentView/${studentDBID}`
  }




  return (
    <>
 
      <div className="container vw-100 " style={{ maxWidth: "500px" }}>

        <div className="card bg-info text-white p-2">
          <h5 className="m-1">課程編號:{classDBID} - {classDate} 資料維護</h5>
        </div>

        <div className="mt-2">
          <div className="card mt-2 ">
            <div className="card-header alert-info">課程基本資料區</div>
            <div className="card-body ">


              <div className="m-1">
                <div className="input-group">
                  <div className="input-group-prepend">
                    <span
                      className="input-group-text alert-info"
                      id="validationTooltipUsernamePrepend"
                    >
                      開課日期
                      </span>
                  </div>
                  <input
                    type="date"
                    id="classDate"
                    className="form-control"
                    value={classDate}
                    onChange={(e) => {
                      let value = e.target.value
                      setClassDate(value)
                      let dateWeek = new Date(value).getDay()
                      var weekValue = day_list[dateWeek]
                      setWeek(weekValue)
                    }}
                    aria-describedby="validationTooltipUsernamePrepend"
                    required
                  />
                  <div className="input-group-prepend"><span className="input-group-text alert-info"
                    id="validationTooltipUsernamePrepend">星期：{week}</span></div>
                </div>
              </div>

              <div className="m-1">
                <div className="input-group">
                  <div className="input-group-prepend">
                    <span
                      className="input-group-text alert-info"
                      id="validationTooltipUsernamePrepend"
                    >
                      開課時間
                      </span>
                  </div>
                  <input
                    type="text"
                    id="classTime"
                    className="form-control"
                    aria-describedby="validationTooltipUsernamePrepend"
                    required
                  />
                </div>
              </div>

              <div className="m-1">
                <div className="input-group">
                  <div className="input-group-prepend">
                    <span
                      className="input-group-text alert-info"
                      id="validationTooltipUsernamePrepend"
                    >
                      班別年紀
                      </span>
                  </div>
                  <select id="age" className="custom-select" required>
                    <option selected>請選擇...</option>
                    <option value="1-2Y">1-2Y</option>
                    <option value="2-3Y">2-3Y</option>
                    <option value="2M">2M</option>
                    <option value="3-6M">3-6M</option>
                    <option value="3-7Y">3-7Y</option>
                    <option value="6-12M">6-12M</option>
                    <option value="ELP">ELP</option>
                    <option value="備用">備用</option>
                    <option value="暑期班">暑期班</option>
                    <option value="水中攝影">水中攝影</option>
                    <option value="自由練習">自由練習</option>
                    <option value="體驗">體驗</option>
                  </select>
                </div>
              </div>

              <div className="m-1">
                <div className="input-group">
                  <div className="input-group-prepend">
                    <span
                      className="input-group-text alert-info"
                      id="validationTooltipUsernamePrepend"
                    >
                      人數上限
                      </span>
                  </div>
                  <input
                    type="text"
                    id="limit"
                    className="form-control"
                    aria-describedby="validationTooltipUsernamePrepend"
                    required
                  />
                </div>
              </div>


              <div className="m-1">
                <div className="input-group">
                  <div className="input-group-prepend">
                    <span
                      className="input-group-text alert-info"
                      id="validationTooltipUsernamePrepend"
                    >
                      開放APP選課
                      </span>
                  </div>

                  <select id="webApp" className="custom-select" required>
                    <option value="true">Yes</option>
                    <option value="false" selected>No</option>
                  </select>
                </div>
              </div>
              <div className="m-1 ">
                <div className="input-group">
                  <div className="input-group-prepend">
                    <span
                      className="input-group-text alert-info"
                      id="validationTooltipUsernamePrepend"
                    >
                      是否開課
                      </span>
                  </div>

                  <select id="available" className="custom-select" required>
                    <option value="true">Yes</option>
                    <option value="false" selected>No</option>
                  </select>
                </div>
              </div>

              <div className="m-1 ">
                <div className="input-group " >
                  <div className="input-group-prepend">
                    <span
                      className="input-group-text alert-info"
                      id="validationTooltipUsernamePrepend"
                    >
                      是否開放體驗
                      </span>
                  </div>

                  <select id="trial" className="custom-select" required disabled>
                    <option value="true">Yes</option>
                    <option value="false" selected>No</option>
                  </select>
                </div>
              </div>

            </div>
          </div>

          <div className="card mt-2 ">
            <div className="card-header alert-primary">授課資料區</div>
            <div className="card-body">
              <div className="m-1">
                <div className="input-group">
                  <div className="input-group-prepend">
                    <span
                      className="input-group-text alert-primary"
                      id="validationTooltipUsernamePrepend"
                    >
                      教練姓名
                      </span>
                  </div>
                  <select id="coachID" className="custom-select" required onChange={(e)=>{
                    setCoachIDState(e.target.value)
                  }}>
                    <option value="">尚未指派</option>
                    {staffList.map(item=>{
                      let dayDiff = dateDiff(classDate, today) // 日期差
                      if (dayDiff > 0){
                        if(item.coach=="true" && item.alive=="true"){
                          return  <option value={item.staffDBID}>{item.name}</option>
                        }
                      }else{
                        //發生在過去的 修改不限定 是否仍在職
                        if(item.coach=="true" ){
                          return  <option value={item.staffDBID}>{item.name}</option>
                        }
                      }
                     
                    })}
                
             
                  </select>
                </div>
              </div>
              <div className="m-1">
                <div className="input-group">
                  <div className="input-group-prepend">
                    <span
                      className="input-group-text alert-primary"
                      id="validationTooltipUsernamePrepend"
                    >
                      助教
                      </span>
                  </div>
                  <select id="assistantID" className="custom-select" required>
                  <option value="">不需助教</option>
                    {staffList.map(item=>{
               
                     let dayDiff = dateDiff(classDate, today) // 日期差
                     if (coachIDState!=item.staffDBID){
                      if (dayDiff > 0){
                        if(item.coach=="true" && item.alive=="true"){
                          return  <option value={item.staffDBID}>{item.name}</option>
                        }
                      }else{
                        //發生在過去的 修改不限定 是否仍在職
                        if(item.coach=="true" ){
                          return  <option value={item.staffDBID}>{item.name}</option>
                        }
                      }
                     }
                    
                    
                    })}
                
             
                  </select>
                </div>
              </div>

              <div className="m-1">
                <div className="input-group">
                  <div className="input-group-prepend">
                    <span
                      className="input-group-text alert-primary"
                      id="validationTooltipUsernamePrepend"
                    >
                      課程主題
                      </span>
                  </div>
                  <select
                    
                    id="classProcess"
                    className="form-control"
                    aria-describedby="validationTooltipUsernamePrepend"
                    required
                  >
                  <option>請選擇</option>
                  
                  {classProcessList.map(item=>{
                    console.log(item.classProcess)
                    return <option>{item.classProcess}</option>
                  })}
               </select>
                </div>
                
              </div>


              </div>

            </div>
            <div className="card  mt-2 p-1" style={{ width: "100%" }}>
                <button
                  type="button"
                  onClick={handelSummit}
                  className="btn btn-danger "
                >
                  更新
                </button>
                <Link to={`/SingleClassView/${classDBID}`}>
                  <div
                    className="btn alert-dark mt-1" style={{ width: "100%" }}
                  >
                    取消
                  </div>
                </Link>
              </div>
         
        </div>
      </div>
    </>
  )

};


export default ClassEdit;