
import React, {
  useState,
  useEffect,
} from "react";
import getUrl from "../function/getUrl.js"
import FunBarClass from "./funBarClass.js";
import { Link, useParams } from "react-router-dom";
import getDay from "../function/getDay.js"
import dateDiff from "../function/dateDiff.js"
import checkLogin from "../function/checkLogin.js"
//課程畫面元件 - 製作單堂
const ClassAddBatch = () => {
checkLogin()
  let param = useParams(); //功能項目

  let classDBID=null

  const url = getUrl()
  const today=getDay(0,"-")

  const [classDate, setClassDate] = useState(null)


  const token=sessionStorage.getItem('token')


  


  

  useEffect(()=>{
    // document.getElementById("classDate").value = param.classDate
    // document.getElementById('saveButton').disabled=true
  
  

  //   getFetchData(today)
  //   const day_list = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];
  // let dateWeek = new Date(classDateNew).getDay()
  // var weekValue = day_list[dateWeek]
  // setWeek(weekValue)
  

  },[])
  

  
 function checkEndDate(){
  let startDate=document.getElementById("startDate").value
  let endDate=document.getElementById("endDate").value
  if (endDate < startDate){
    alert("終止日不可以早於起始日")
    document.getElementById("endDate").value=startDate
  }
 }

 
function checkClassTime(){
  let classTime=document.getElementById("classTime").value
  if (classTime.length !=4 || classTime < "0900" || classTime > "2100") {
    alert("開課時間有誤")
    document.getElementById("classTime").value=null
  }
}


  

  function getSelect(e) {
    let selected = [];
    let optionFollow = e;

    for (let i = 0; i < optionFollow.length; i++) {
      if (optionFollow[i].selected) {
        selected.push(optionFollow[i].value);
      }
    }
    return selected;
  }

  function checkValue(){
    if (document.getElementById("classTime").value==null){
      console.log(document.getElementById("classTime").value)
      alert("classTim")
      return false
    }
    
  }

  function handelSummit(e) {
    let operator=sessionStorage.getItem("staffDBID")

    var formData = new FormData();

    // if (!checkValue()){
    //   return
    // }
  

    formData.append("classTime", document.getElementById("classTime").value);
    formData.append("age", document.getElementById("age").value);
    formData.append("limit", document.getElementById("limit").value);
    formData.append("startDate", document.getElementById("startDate").value);
    formData.append("endDate", document.getElementById("endDate").value);
    formData.append("weekday", document.getElementById("weekday").value);
    formData.append("available", document.getElementById("available").value);
    formData.append("webApp", document.getElementById("webApp").value);
    formData.append("trial", document.getElementById("trial").value);
    formData.append("operator", operator);
    formData.append("token", sessionStorage.getItem("token"));


    fetch(`${url}/appPost/makeClassBatch`, {
      method: "POST",
      body: formData,
      mode: "cors",
    })
      .then((res) => {
        return res.json()

      }).then(()=>{
        window.location.href = `/GetDailyTable/${document.getElementById("classDate").value}`
      }).catch(err=>{

        console.log('錯誤:', err);
        alert('伺服器回應建立失敗')
        window.location.href = `/GetDailyTable/${document.getElementById("classDate").value}`
      })

    
  }




  return (
    <>
 
      <div className="container vw-100 " style={{ maxWidth: "500px" }}>

        <div className="card bg-info text-white p-2">
          <h5 className="m-1">批次新增單堂</h5>
        </div>

        <div className="mt-2">
          <div className="card mt-2 ">
            <div className="card-header alert-info">課程基本資料區</div>
            <div className="card-body ">
              <div className="m-1">
                <div className="input-group">
                  <div className="input-group-prepend">
                    <span
                      className="input-group-text alert-info"
                      id="validationTooltipUsernamePrepend"
                    >
                      開課起始日
                      </span>
                  </div>
                  <input
                    type="date"
                    id="startDate"
                    className="form-control"
                    value={classDate}
                    onChange={()=>{
                      let date=document.getElementById("startDate").value
                      const pickupDate = new Date(date);
                      console.log(pickupDate.getDay())
                      let weekday=pickupDate.getDay()
                      document.getElementById("weekday").value=weekday
                      
                      const endDate=document.getElementById("endDate").value
                      console.log(endDate)
                      if (endDate=="") {
                        document.getElementById("endDate").value=date
                      }

                      checkEndDate()
                      
                    }}
                    aria-describedby="validationTooltipUsernamePrepend"
                    required
                  />
         
                </div>
              </div>

              <div className="m-1">
                <div className="input-group">
                  <div className="input-group-prepend">
                    <span
                      className="input-group-text alert-info"
                      id="validationTooltipUsernamePrepend"
                    >
                      開課終止日
                      </span>
                  </div>
                  <input
                    type="date"
                    id="endDate"
                    className="form-control"
                    onChange={checkEndDate}
                   
                    aria-describedby="validationTooltipUsernamePrepend"
                    required
                  />
     
                </div>
              </div>
              <div className="m-1">
                <div className="input-group">
                  <div className="input-group-prepend">
                    <span
                      className="input-group-text alert-info"
                      id="validationTooltipUsernamePrepend"
                    >
                      設定星期
                      </span>
                  </div>
                  <select id="weekday" className="custom-select" required>
                  <option value="0">星期天</option>
                    <option value="1">星期一</option>
                    <option value="2">星期二</option>
                    <option value="3">星期三</option>
                    <option value="4">星期四</option>
                    <option value="5">星期五</option>
                    <option value="6">星期六</option>
                   
                  </select>
                </div>
              </div>

              <div className="m-1">
                <div className="input-group">
                  <div className="input-group-prepend">
                    <span
                      className="input-group-text alert-info"
                      id="validationTooltipUsernamePrepend"
                    >
                      開課時間
                      </span>
                  </div>
                  <input
                    type="text"
                    id="classTime"
                    className="form-control"
                    aria-describedby="validationTooltipUsernamePrepend"
                    placeholder="請填入4位數開課時間"
                    onBlur={checkClassTime}
  
                    required
                  />
                </div>
              </div>
 
              <div className="m-1">
                <div className="input-group">
                  <div className="input-group-prepend">
                    <span
                      className="input-group-text alert-info"
                      id="validationTooltipUsernamePrepend"
                    >
                      班別年紀
                      </span>
                  </div>
                  <select id="age" className="custom-select" required>
                  <option value="3-7Y">3-7Y</option>
                    <option value="1-2Y">1-2Y</option>
                    <option value="2-3Y">2-3Y</option>
                    <option value="2M">2M</option>
                    <option value="3-6M">3-6M</option>
                    <option value="6-12M">6-12M</option>
                    <option value="ELP">ELP</option>
                    <option value="備用">備用</option>
                    <option value="暑期班">暑期班</option>
                    <option value="水中攝影">水中攝影</option>
                    <option value="自由練習">自由練習</option>
                    <option value="體驗">體驗</option>
                  </select>
                </div>
              </div>

              <div className="m-1">
                <div className="input-group">
                  <div className="input-group-prepend">
                    <span
                      className="input-group-text alert-info"
                      id="validationTooltipUsernamePrepend"
                    >
                      人數上限
                      </span>
                  </div>
                  <select id="limit" className="custom-select" required>
                  <option value="7">7</option>
                  <option value="6">6</option>
                  <option value="5">5</option>
                  <option value="4">4</option>
                  <option value="3">3</option>
                    <option value="2">2</option>
                    <option value="1">1</option>
                  </select>
                </div>
              </div>


              <div className="m-1">
                <div className="input-group">
                  <div className="input-group-prepend">
                    <span
                      className="input-group-text alert-info"
                      id="validationTooltipUsernamePrepend"
                    >
                      開放APP選課
                      </span>
                  </div>

                  <select id="webApp" className="custom-select" required>
                    <option value="true">Yes</option>
                    <option value="false" >No</option>
                  </select>
                </div>
              </div>
              <div className="m-1 ">
                <div className="input-group">
                  <div className="input-group-prepend">
                    <span
                      className="input-group-text alert-info"
                      id="validationTooltipUsernamePrepend"
                    >
                      是否開課
                      </span>
                  </div>

                  <select id="available" className="custom-select" required>
                    <option value="true"selected>Yes</option>
                    <option value="false" >No</option>
                  </select>
                </div>
              </div>

              <div className="m-1 ">
                <div className="input-group">
                  <div className="input-group-prepend">
                    <span
                      className="input-group-text alert-info"
                      id="validationTooltipUsernamePrepend"
                    >
                      是否開放體驗
                      </span>
                  </div>

                  <select id="trial" className="custom-select" required >
                  <option value="true">Yes</option>
                  <option value="false" >No</option>
                    
                   
                  </select>
                </div>
              </div>

            </div>
          </div>

          
            <div className="card  mt-2 p-1" style={{ width: "100%" }}>
                <button
                  type="button"
                  onClick={handelSummit}
                  className="btn btn-danger "
                  id="saveButton"
                >
                  建立
                </button>
                <Link to={`/SingleClassView/${classDBID}`}>
                  <div
                    className="btn alert-dark mt-1" style={{ width: "100%" }}
                  >
                    取消
                  </div>
                </Link>
              </div>
         
        </div>
      </div>
    </>
  )

};


export default ClassAddBatch;