import React, {
  useState,
  useEffect,
  useCallback,
  updateState,
  useReducer,
  useContext,
  createContext,
} from "react";
import { useSelector, useDispatch } from "react-redux";
import { Link, useParams } from "react-router-dom";
import { combineReducers, createStore } from "redux";
import birthdayToAge from "../function/birthdayToAge.js";
import getDay from "../function/getDay.js";
import reloadIcon from "../icon/reload.svg";
import searchIcon from "../icon/search.svg";
import FunBarClass from "./funBarClass.js";
import classIcon from "../icon/swimClass.svg";
import cancelIcon from "../icon/cancel.svg";
import saveIcon from "../icon/save.svg";
import dateDiff from "../function/dateDiff.js"; //日期差
import classReturnOption from "../function/classReturnOption.js"; //處理選單
import GetStudentListComponent from "../pages/getStudentListComponent.js";
import getUrl from "../function/getUrl.js"
import getClassData from "../function/getClassData.js"
import ClassStudentListViewComponent from "../pages/classStudentListViewComponent.js"
import ClassStudentListViewComponentReadOnly from "../pages/classStudentListViewComponentReadOnly.js"
import ClassDataViewComponent from "../pages/classDataViewComponent.js"
import checkLogin from "../function/checkLogin.js"
var imgStyle = {
  width: "30px",
  height: "30px",
};

const url = getUrl()
const token=sessionStorage.getItem("token")

const SingleClassView = () => {
  checkLogin()
  const param = useParams();
  const classDBID = param.classDBID;
  

  return (
    <>

      <ClassDataViewComponent classDBID={classDBID}/> 
      <ClassStudentListViewComponent classDBID={classDBID}/>
    </>
  );
};



export default SingleClassView;
