import React, { useState, useEffect } from 'react';
import { Link, useParams } from 'react-router-dom';
import birthdayToAge from '../function/birthdayToAge.js'

import reloadIcon from '../icon/reload.svg'
import searchIcon from '../icon/search.svg'
import checkLogin from "../function/checkLogin.js"

import getUrl from "../function/getUrl.js"
const token=sessionStorage.getItem("token")
//快速查詢課程
const GetClassList = (props) => {
  checkLogin()
  let param = useParams() //功能項目
  let togo = param.togo
  let title = ""
  let goLink = ""
  let getDataURL = ""
  let url = getUrl()

  //設計功能切換
  //按排訂單。。。。
  switch (togo) {
    case "trial":
      goLink = "/SingleStudentTrialView/"
      title = "體驗報名處理"
      getDataURL = `${url}/appGet/getStudentByTrialFlag`
      break;
    case "detail":
      goLink = "/SingleClassView/"
      title = "課程維護"
      getDataURL = `${url}/appGet/getClassByContext`
      break;
    case "classProcess":
      goLink = "/singleClassViewForCoach/"
      title = "學習進度維護"
      getDataURL = `${url}/appGet/getClassByContext`
      break; 
    default:
      goLink = "/"
      break;
  }
  //進度條
  let loadingState = <>
    <h5 className="text-info mt-5">資料載入中...</h5>
    <div className="progress w-50">
      <div className="progress-bar progress-bar-striped progress-bar-animated bg-info" role="progressbar" aria-valuenow="75" aria-valuemin="0" aria-valuemax="100" style={{ width: '100%' }}></div>
    </div>
  </>
  const [classList, setClassList] = useState(loadingState)

  var imgStyle = {
    width: '20px',
    height: '20px',
  };

  function hanedleState(e) {

    handleSearch()
  }

  function getSelect(e) {

    let optionFollow = e;

    for (let i = 0; i < optionFollow.length; i++) {
      if (optionFollow[i].selected) {
        return optionFollow[i].value;
      }
    }
    return "";
  }

  function handleSearch(e) {
    console.log(token)
    setClassList(loadingState)
    let getAge = getSelect(document.getElementById('searchAge'))
    let getDate = document.getElementById('searchDate').value
    let getWeek = getSelect(document.getElementById('searchWeek'))
    let getTime=document.getElementById('searchTime').value
    let goUrl = `${getDataURL}?token=${token}`
    if (getDate == "" && getAge == "") {
      url = `${getDataURL}`
    } else if (getAge == "") {
      goUrl = `${getDataURL}?date=${getDate}&token=${token}`
    } else if (getDate == "") {
      goUrl = `${getDataURL}?age=${getAge}&token=${token}`
    } else {
      goUrl = `${getDataURL}?age=${getAge}&date=${getDate}&token=${token}`
    }
    console.log("getDate:", getDate)

    console.log('goUrl', goUrl)
    let classArray = []

    fetch(goUrl).then((res) => {
      return res.json();
    }).then(json => {

      json.sort((a, b) => {

        var nameA = a.classTime;
        var nameB = b.classTime;
        if (nameA > nameB) {
          return 1;
        }
        if (nameA < nameB) {
          return -1;
        }


        return 0;
      })

      json.sort((a, b) => {

        var nameA = a.classDate;
        var nameB = b.classDate;
        if (nameA > nameB) {
          return 1;
        }
        if (nameA < nameB) {
          return -1;
        }


        return 0;
      })



      var day_list = ['日', '一', '二', '三', '四', '五', '六'];
      let count = 0
      for (let x in json) {

        // if (json[x].available == "true") {
        //   json[x].available = "Yes"
        // } else {
        //   json[x].available = "No"
        // }

        var dateWeek = new Date(json[x]['classDate']).getDay()
        var week = day_list[dateWeek]
        console.log("getTime",getTime,"json[x]['classTime']",json[x]['classTime'])
        if ((getWeek == "" || getWeek == week ) &&(getTime == "" || getTime == json[x]['classTime'] )) {
          count += 1
          //如果劃位超過上限，則顯示紅色警示
          let textCSS = "text-muted"
          if (json[x]['reservation'] > json[x]['limit']) {
            textCSS = "text-danger text-weight-bold"
          }


          let singleClass =
            <tr onClick={() => { window.location.href = `${goLink}${json[x]['classDBID']}` }} className={textCSS} style={{ textDecoration: 'none' }}>
              <th scope="row">{count}</th>
              <td>{json[x]['classDBID']}</td>
              <td>{json[x]['classDate']}</td>
              <td>{week}</td>
              <td>{json[x]['classTime']}</td>
              <td>{json[x]['age']}</td>
              <td>{json[x]['available']}</td>
              <td>{json[x]['limit']}</td>
              <td>{json[x]['booking']}</td>
              <td>{json[x]['backup']}</td>
              <td>{json[x]['cancel']}</td>
            </tr>

          classArray.push(singleClass)



        }





      }

    }).then(() => {
      setClassList(classArray)
      // document.getElementById('searchAge').value = ""
      // document.getElementById('searchDate').value = ""
      // document.getElementById('searchWeek').value = ""
      // document.getElementById('searchWeek').disabled = true
      let test=document.getElementById('searchAge').options
      console.log(test[0])
      console.log(document.getElementById('searchAge').options)
    })
  }

  function ChangeDisabled(e) {
    let value = document.getElementById('searchAge').value
    if (value == "") {
      document.getElementById('searchWeek').disabled = true;　// 變更欄位為可用
      document.getElementById('searchTime').disabled = true;　// 變更欄位為可用
    } else {
      document.getElementById('searchWeek').disabled = false;　// 變更欄位為禁用
      document.getElementById('searchTime').disabled = false;　
    }
  }

  useEffect(() => {
    handleSearch()
  }, [])

  return (
    <>
      <div className="container text-center">
        <div className="card alert-info p-2 " >
          <div className="container vw-75" style={{ maxWidth: "500px" }}>
            <h5 className="m-1">{title}</h5>

            <h6 className="m-1 text-muted">請先挑選課程</h6>
            <div className="card-body">
              <div className="input-group mb-3">
                <div className="input-group-prepend">
                  <span className="input-group-text" id="basic-addon1"> 日期</span>
                </div>
                <input type="date" className="form-control " id="searchDate" aria-label="Username" aria-describedby="basic-addon1" />


              </div>



              <div className="input-group mt-1">
                <div className="input-group-prepend">
                  <span
                    className="input-group-text alert-primary"
                    id="validationTooltipUsernamePrepend"
                  >
                    年紀
                  </span>
                </div>

                <select
                  id="searchAge"

                  className="custom-select"
                  required

                  onChange={ChangeDisabled}
                >
                  <option selected value="">全部</option>
                  <option value="1-2Y">1-2Y </option>
                  <option value="2-3Y">2-3Y </option>
                  <option value="3-7Y">3-7Y </option>
                  <option value="ELP">ELP </option>
                  <option value="2M">2M </option>
                  <option value="3-6M">3-6M </option>
                  <option value="6-12M">6-12M </option>
                  <option value="體驗">體驗 </option>
                  <option value="暑期班">暑期班 </option>
                  <option value="水中攝影">水中攝影 </option>
                  <option value="自由練習">自由練習 </option>
                  <option value="備用">備用 </option>

                </select>
              </div>

              <div className="input-group mt-1">
                <div className="input-group-prepend">
                  <span
                    className="input-group-text alert-primary"
                    id="validationTooltipUsernamePrepend"
                  >
                    星期
                  </span>
                </div>

                <select
                  id="searchWeek"
                  className="custom-select"
                  disabled
                >
                  <option selected value="">全部</option>
                  <option value="日">日 </option>
                  <option value="一">一 </option>
                  <option value="二">二 </option>
                  <option value="三">三 </option>
                  <option value="四">四 </option>
                  <option value="五">五 </option>
                  <option value="六">六 </option>
                </select>
              </div>

              <div className="input-group mt-1">
                <div className="input-group-prepend">
                  <span
                    className="input-group-text alert-primary"
                    id="validationTooltipUsernamePrepend"
                  >
                    時間
                  </span>
                </div>

                <input
                  id="searchTime"
                  type="number"
                  className="form-control"
                  placeholder="輸入時間 EX：0910"
                  disabled
                />
                 
              </div>
            </div>
            <div className="w-100 container ">
              <button className="btn btn-info" onClick={handleSearch} style={{ width: "60%" }} ><img src={searchIcon} style={imgStyle} /> 送出</button>
              <button className="btn alert-dark ml-3 text-mute" onClick={()=>{
                window.location.href="/getClassList/detail"
              }} style={{ width: "40" }} ><img src={reloadIcon} style={imgStyle} /> 清除</button>
            </div>



            <div>
            </div>

          </div>

        </div>

        <hr />


        <table class="table table-striped">
          <thead>
            <tr className="text-center">
              <th scope="row">#</th>
              <th scope="col">編號</th>
              <th scope="col">日期</th>
              <th scope="col">星期</th>
              <th scope="col">時間</th>
              <th scope="col">年紀</th>
              <th scope="col">是否開課</th>
              <th scope="col">上限</th>
              <th scope="col">劃位</th>
              <th scope="col">候補</th>
              <th scope="col">取消</th>
            </tr>
          </thead>
          <tbody className="text-center">
            {classList}

          </tbody>
        </table>
      </div>
    </>
  )

}

export default GetClassList;