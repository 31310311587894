import React, { useState, useEffect } from 'react';
import { Link, useParams } from 'react-router-dom';
import birthdayToAge from '../function/birthdayToAge.js'
import saleIcon from "../icon/sale.svg";
import reloadIcon from '../icon/reload.svg'
import searchIcon from '../icon/search.svg'
import FunBarTrial from '../pages/funBarTrial.js'
import getUrl from '../function/getUrl'
const url = getUrl()
const token = sessionStorage.getItem("token")

var fontStyle = {
  fontSize: "0.5em",
};
//快速學生資訊View
const SingleStudentTrialView = (props) => {
  let param = useParams() //功能項目
  let studentDBID = param.studentDBID
  let goLink = ""
  let total = 0 //
  let count = 0 //總購課數
  let dayoff = 0 //請假數
  let done = 0 //完課數
  let reservation = 0
  const [student, setStudent] = useState(null)
  const [orderList, setOrderList] = useState(null)
  const [classList, setClassList] = useState(null)
  const [stuentTrialFollowList, setStuentTrialFollowList] = useState(null)
  const [studentNameState, setStudentName] = useState(null)
  const [addTrialFollowBtn, setAddTrialFollowBtn] = useState(null)
  var imgStyle = {
    width: '20px',
    height: '20px',
  };

function del(){
 

    let code = document.getElementById('securityCode').value
    // alert(code)
    if (code == studentDBID) {
      if (window.confirm(`確定刪除${studentNameState}資料？`)) {
        // alert(`${url}/appGet/turnoffTrial?studentDBID=${studentDBID}&token=${token}`)
        fetch(`${url}/appGet/turnoffTrial?studentDBID=${studentDBID}&token=${token}`).then(res => {
          window.location.href = "/"
        })
      }

    }
  
}

  function getData() {
    let url = getUrl()

    fetch(`${url}/appGet/singleStudentView?studentDBID=${studentDBID}&token=${token}`).then((res) => {
      return res.json();
    }).then(json => {
      console.log(json)
      // if (json.trialFlag == "true") {

      //   setAddTrialFollowBtn(
      //     <>
      //       <div className="mt-2 btn btn-danger" onClick={() => {
      //         window.location.href = `/studentTrialFollow/${studentDBID}`
      //       }}>新增體驗追蹤記錄</div>

      //     </>
      //   )


      // }
            setAddTrialFollowBtn(
          <>
            <div className="mt-2 btn btn-danger" onClick={() => {
              window.location.href = `/studentTrialFollow/${studentDBID}`
            }}>新增體驗追蹤記錄</div>

          </>
        )

      console.log(json['birthday'])
      let age = birthdayToAge(json['birthday'], "/")
      setStudentName(json['name'])
      let singleStudent =
        <>
          <div className="card mt-1  alert-success" >
            <div className="row">
              <div className="col-sm m-0 ">
                <div className="card-body rounded border-0 ">
                  <h6 className="card-title">基本資料</h6>

                  <ul className="list-group list-group-flush text-left ">

                    <li className="list-group-item">姓名：{json['name']}</li>
                    <li className="list-group-item">暱稱：{json['nickName']}</li>
                    <li className="list-group-item">編號：{json['studentDBID']}</li>
                    <li className="list-group-item">性別：{json['gender']}</li>
                    <li className="list-group-item">身分：{json['studentType']}</li>
                    <li className="list-group-item">生日：{json['birthday']}</li>
                    <li className="list-group-item">年紀：{age[0]}</li>
                  </ul>
                </div>
              </div>
              <div className="col-sm m-0 ">
                <div className="card-body rounded border-0 ">
                  <h6 className="card-title">家長資料</h6>

                  <ul className="list-group list-group-flush text-left ">
                    <li className="list-group-item">姓名：{json['parent']}</li>
                    <li className="list-group-item">稱謂：{json['title']}</li>
                    <li className="list-group-item">電話：
                    <button
                        type="button"
                        className="btn btn-sm btn-light dropdown-toggle  p-1"
                        data-toggle="dropdown"
                        aria-haspopup="true"
                        aria-expanded="false"
                      >
                        09********
                      </button>
                      <div className="dropdown-menu">
                        <div className="dropdown-item">{json["phone"]}</div>
                      </div>
                    </li>
                    <li className="list-group-item">APP密碼：
                      <div className="btn-group ">
                        <button
                          type="button"
                          className="btn btn-sm btn-light dropdown-toggle  p-1"
                          data-toggle="dropdown"
                          aria-haspopup="true"
                          aria-expanded="false"
                        >
                          ****
                      </button>
                        <div className="dropdown-menu">
                          <div className="dropdown-item">{json["password"]}</div>
                        </div>
                      </div>
                    </li>

                    <li className="list-group-item">FB：{json['fb']}</li>
                    <li className="list-group-item">Line：{json['line']}</li>
                    <li className="list-group-item">Email：
                    <button
                        type="button"
                        className="btn btn-sm btn-light dropdown-toggle  p-1"
                        data-toggle="dropdown"
                        aria-haspopup="true"
                        aria-expanded="false"
                      >
                        {json['email'].substring(0, 3)}***{json['email'].substring(json['email'].length - 10, json['email'].length)}
                      </button>
                      <div className="dropdown-menu">
                        <div className="dropdown-item">{json['email']}</div>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>

              <div className="col-sm m-0 ">
                <div className="card-body rounded border-0 ">
                  <h6 className="card-title">備註</h6>

                  <ul className="list-group list-group-flush text-left ">
                    <li className="list-group-item">介紹人：{json['introPeople']}</li>
                    <li className="list-group-item">來源：{json['comefrom']}</li>
                    <li className="list-group-item">地址：
                    <button
                        type="button"
                        className="btn btn-sm btn-light dropdown-toggle  p-1"
                        data-toggle="dropdown"
                        aria-haspopup="true"
                        aria-expanded="false"
                      >
                        {json['address'].substring(0, 3)}***{json['address'].substring(json['address'].length - 3, json['address'].length)}
                      </button>
                      <div className="dropdown-menu">
                        <div className="dropdown-item">{json['address']}</div>
                      </div>
                    </li>
                    <li className="list-group-item">游泳經驗：{json['swimExp']}</li>
                    <li className="list-group-item">健康聲明：{json['healthMemo']}</li>
                    <li className="list-group-item">備註：{json['memo']}</li>
                    <li className="list-group-item">櫃台註記：<button
                      type="button"
                      className="btn btn-sm btn-light dropdown-toggle  p-1"
                      data-toggle="dropdown"
                      aria-haspopup="true"
                      aria-expanded="false"
                    >
                      Memo
                      </button>
                      <div className="dropdown-menu">
                        <div className="dropdown-item">{json['parentMemo']}</div>
                      </div></li>

                  </ul>
                </div>
              </div>
            </div>
          </div>

        </>

      setStudent(singleStudent)


      let orderArray = []
      json['orderList'].sort((a, b) => {
        var nameA = a.orderDate;
        var nameB = b.orderDate;
        if (nameA > nameB) {
          return -1;
        }
        if (nameA < nameB) {
          return 1;
        }
        return 0;
      })

      for (let x in json['orderList']) {
        //count+=parseInt(json['orderList'][x]['classAmount'],10)
        if (json['orderList'][x]['alive'] == "true") {
          count++
        }
        let singleOrder =
          <>

            <div className="row p-1 text-muted " key={json['orderList'][x]['orderID']}>
              <div className="col-sm">{json['orderList'][x]['orderDBID']}</div>
              <div className="col-sm">{json['orderList'][x]['orderDate'].substring(0, 10)} </div>
              <div className="col-sm">{json['orderList'][x]['eventName']} </div>
              <div className="col-sm">{json['orderList'][x]['payment']} </div>
              <div className="col-sm">{json['orderList'][x]['total']} </div>


            </div>
          </>
        orderArray.push(singleOrder)
      }
      // if (count == 0) {
      //   setOrderList(
      //     <div className="btn btn-success" onClick={() => {
      //       window.location.href = `/orderAddTrial/${studentDBID}`
      //     }}>
      //       <img src={saleIcon} style={imgStyle} alt="Home" />
      //       <span className=" mr-1" style={fontStyle}>
      //         {" "}
      //               體驗付費
      //             </span>
      //     </div>
      //   )
      // } else {
      //   setOrderList(orderArray)
      // }

      setOrderList(orderArray)

      let classArray = []
      json['classList'].sort((a, b) => {
        var nameA = a.classDate;
        var nameB = b.classDate;
        if (nameA > nameB) {
          return -1;
        }
        if (nameA < nameB) {
          return 1;
        }
        return 0;
      })




      for (let x in json['classList']) {
        let getDate = json['classList'][x]['classDate'].substring(0, 10)
        var dateWeek = new Date(getDate).getDay()
        var day_list = ['日', '一', '二', '三', '四', '五', '六'];
        var week = day_list[dateWeek]

        let singleClass = ''
        if (json['classList'][x]['state'] == '- -') {

          singleClass =
            <>
              <div className="row p-1 text-danger font-weight-bold" key={json['classList'][x]['classID']}>
                <div className="col-sm">{json['classList'][x]['classDate'].substring(0, 10)} ({week})</div>
                <div className="col-sm">{json['classList'][x]['classTime']}</div>
                <div className="col-sm">{json['classList'][x]['age']}</div>
                <div className="col-sm">{json['classList'][x]['studentType']} </div>
                <div className="col-sm">已劃位 </div>
              </div>
            </>
        } else {

          singleClass =
            <>
              <div className="row p-1 text-muted" key={json['classList'][x]['classID']} onClick={() => {
                window.location.href = `/SingleClassView/${json["classList"][x]["classDBID"]}`
              }}>
                <div className="col-sm">{json['classList'][x]['classDate'].substring(0, 10)} ({week})</div>
                <div className="col-sm">{json['classList'][x]['classTime']}</div>
                <div className="col-sm">{json['classList'][x]['age']}</div>
                <div className="col-sm">{json['classList'][x]['studentType']} </div>
                <div className="col-sm">{json['classList'][x]['studentState']} </div>
              </div>
            </>
        }

        classArray.push(singleClass)

        switch (json['classList'][x]['state']) {
          case '請假':
            dayoff += 1
            break;
          case '完課':
            done += 1
            break;
          case '- -':
            reservation += 1
            break;
        }
      }
      setClassList(classArray)
      total = dayoff + done

    })
  };

  const getStudentTrialFollowList = () => {

    fetch(`${url}/appGet/getStudentTrialFollow?token=${token}&studentDBID=${studentDBID}`).then((res) => {
      return res.json()
    }).then(json => {
      console.log(json)
      let studentTrialFollowArray = []
      json.map(item => {
        let singleLine = (
          <div className="row p-1 mt-2 text-muted">
            <div className="col-sm">{item.date} </div>
            <div className="col-sm">{item.contactType} </div>
            <div className="col-sm">{item.contact} </div>
            <div className="col-sm">{item.result} </div>
            <div className="col-sm">{item.nextStep} </div>
          </div>
        )
        studentTrialFollowArray.push(singleLine)

      })
      setStuentTrialFollowList(studentTrialFollowArray)
    })
  };

  useEffect(() => {
    getData()
    getStudentTrialFollowList()
    document.getElementById("goCancelBtn").disabled = true

  }, [])

  const BtnToDel = () => {

    return <>

     <div  style={{"width":"50%"}}>
        <div className="input-group mb-3">
          <div className="input-group-prepend">
            <span
              className="input-group-text alert-info"
              id="validationTooltipUsernamePrepend"
            >
              安全確認
                      </span>

          </div>

          <input
            type="text"
            id="securityCode"
            className="form-control "
            aria-describedby="validationTooltipUsernamePrepend"
            placeholder={`請輸入 ${studentDBID}`}
            onChange={(e) => {
              let value = e.target.value
              console.log(value)

              if (value == studentDBID) {
                document.getElementById("goCancelBtn").disabled = false
              } else {
                document.getElementById("goCancelBtn").disabled = true
              }

            }}
           
            required
          />
          <div  className="btn btn-danger " id="goCancelBtn"  onClick={del}  >刪除 {studentNameState} 資料</div>
        </div>

  
        </div>

    </>
  }
  return (
    <>
      <div className="container text-center">
        <FunBarTrial studentDBID={studentDBID} studentType={'trial'} />
        <div className=" alert-warning p-2 rounded-bottom">

          <h5 className="m-1">{studentNameState} 體驗報名學員資料  </h5>

        </div>

        {student}

        <div className="card alert-success p-2 mt-5">
          <h5 className="m-1">體驗付費記錄</h5>
          <div>

          </div>
        </div>
        <div className="row p-1 mt-2">
          <div className="col-sm">編號 </div>
          <div className="col-sm">日期 </div>
          <div className="col-sm">專案名稱 </div>
          <div className="col-sm">付款方式 </div>
          <div className="col-sm">金額 </div>

        </div>
        <div className="row ">
          <div className="col-sm mt-0 ml-2 mr-2"><hr /></div>
        </div>
        {orderList}
        <br />
        <div className="btn btn-success" onClick={() => {
          window.location.href = `/orderAddTrial/${studentDBID}`
        }}>
          <img src={saleIcon} style={imgStyle} alt="Home" />
          <span className=" mr-1" style={fontStyle}>
            {" "}
                    體驗付費
                  </span>
        </div>

        <br />
        <div className="card alert-danger p-2 mt-5">
          <h5 className="m-1">上課記錄</h5>
        </div>

        <div className="row ">
          <div className="col-sm mt-0 ml-2 mr-2"><hr /></div>
        </div>
        <div className="row p-1 mt-2">
          <div className="col-sm">日期 </div>
          <div className="col-sm">時間 </div>
          <div className="col-sm">班別 </div>
          <div className="col-sm">身分 </div>
          <div className="col-sm">狀態 </div>
        </div>
        <div className="row ">
          <div className="col-sm mt-0 ml-2 mr-2"><hr /></div>
        </div>
        {classList}


        <br />

        <div className="card alert-primary p-2 mt-5">
          <h5 className="m-1">連絡記錄</h5>
        </div>

        <div className="row ">
          <div className="col-sm mt-0 ml-2 mr-2"><hr /></div>
        </div>
        <div className="row p-1 mt-2">
          <div className="col-sm">日期 </div>
          <div className="col-sm">連絡方式 </div>
          <div className="col-sm">連絡內容 </div>
          <div className="col-sm">結果 </div>
          <div className="col-sm">狀態 </div>
        </div>
        <div className="row ">
          <div className="col-sm mt-0 ml-2 mr-2"><hr /></div>
        </div>

        {stuentTrialFollowList}
        {addTrialFollowBtn}

        {/* <div className="mt-2 btn btn-danger" onClick={() => {
          window.location.href = `/studentTrialFollow/${studentDBID}`
        }}>新增體驗追蹤記錄</div>
      </div> */}

      </div>
     
      <div className="mt-5 p-2 alert-dark" align="center"><BtnToDel /></div>
    </>
  )

}

export default SingleStudentTrialView;