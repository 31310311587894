import React ,{useState,useEffect} from 'react';
import { Link ,useParams} from 'react-router-dom';
import birthdayToAge from '../function/birthdayToAge.js'
import getUrl from '../function/getUrl'
import reloadIcon from '../icon/reload.svg'
import searchIcon from '../icon/search.svg'
import checkLogin from "../function/checkLogin.js"


const url=getUrl()
//快速查詢學生
const token=sessionStorage.getItem("token")
const GetStudentListDiffDays=(props)=>{
    checkLogin()
    const [studentState,setStudentState]=useState('all')
    let param=useParams() //功能項目

    let title=""
    let goLink=""
    let getDataURL=""
    //設計功能切換
    //按排訂單。。。。

    goLink="/SingleStudentView/"
    title="學員有效期限60天內到期名單"
    getDataURL=`${url}/appGet/GetStudentLastDayDiffDays`
    //進度條
    let loadingState=<>
    <h5 className="text-info mt-5">資料載入中...</h5>
    <div className="progress w-50">
        <div className="progress-bar progress-bar-striped progress-bar-animated bg-info" role="progressbar" aria-valuenow="75" aria-valuemin="0" aria-valuemax="100" style={{width: '100%'}}></div>
        </div>
    </>
    const [studentList,setStudentList]=useState(loadingState)
   
    var imgStyle = {
        width:'20px',   
        height:'20px',
      };

    function hanedleState(e){
       
        handleSearch()
    }
    function handleSearch(e){
        setStudentList(loadingState)
       
       
   
        console.log('url',url)
        let studentArray=[]
     
        fetch(`${getDataURL}?token=${token}`).then((res)=>{
            return res.json();
        }).then(json=>{


            console.log(json)
            for (let x in json){
                let age=birthdayToAge(json[x]['birthday'],"/")
                
                let singleStudent=
                        <tr onClick={()=>{ window.location.href=`${goLink}${json[x]['studentDBID']}`}} className="text-muted" style={{ textDecoration: 'none' }} key={x}>
                        <th scope="row">{parseInt(x, 10)+1}</th>
                        <td>{json[x]['studentDBID']}</td>
                        <td>{json[x]['name']}</td>
                        <td>{json[x]['nickName']}</td>
                        <td>{json[x]['studentType']}</td>
                        <td>{json[x]['birthday']}</td>
                        <td>{age[0]}</td>
                        <td>{json[x]['parent']}</td>
                    </tr>
    
               

           
    
            }
            
        }).then(()=>{
            setStudentList(studentArray)
        })
    }
    
useEffect(()=>{
    setStudentList(loadingState)
       
       
   
        console.log('url',url)
        let studentArray=[]
     
        fetch(`${getDataURL}?token=${token}`).then((res)=>{
            return res.json();
        }).then(json=>{


            console.log(json)
            for (let x in json){
                let age=birthdayToAge(json[x]['birthday'],"/")
                
                let singleStudent=
                        <tr onClick={()=>{ window.location.href=`${goLink}${json[x]['studentDBID']}`}} className="text-muted" style={{ textDecoration: 'none' }} key={x}>
                        <th scope="row">{parseInt(x, 10)+1}</th>
                        <td>{json[x]['studentDBID']}</td>
                        <td>{json[x]['name']}</td>
                        <td>{json[x]['classTotal']}</td>
                        <td>{json[x]['done']}</td>
                        <td>{json[x]['noshow']}</td>
                        <td>{json[x]['balance']}</td>
                        <td>{json[x]['studentLastDate']}</td>
                        <td>{json[x]['diffDays']}</td>
                       
                    </tr>
    
               

                studentArray.push(singleStudent)
    
            }
            
        }).then(()=>{
            setStudentList(studentArray)
        })
},[])
    
    return (
        <>
        <div className="container text-center">
         <div className="card alert-info p-2 " >
             <div className="container vw-75" style={{maxWidth:"500px"}}>
            <h5 className="m-1">{title}</h5>
           
          

                <div>
                </div>
                
            </div>
           
        </div>

        <hr/>
 
                
                        <table className="table table-striped">
                <thead>
                    <tr className="text-center">
                    <th scope="row">#</th>    
                    <th scope="col">編號</th>
                    <th scope="col">姓名</th>
                    <th scope="col">總堂數</th>
                    <th scope="col">完課</th>
                    <th scope="col">缺課</th>
                    <th scope="col">餘堂</th>
                    <th scope="col">有效期限</th>
                    <th scope="col">有效天數</th>
                    </tr>
                </thead>
                <tbody className="text-center">
                {studentList}
                    
                </tbody>
                </table>
                </div>
        </>
    )
    
}



export default GetStudentListDiffDays;