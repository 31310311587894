import React, { useState, useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import birthdayToAge from "../function/birthdayToAge.js";
import pencilIcon from "../icon/pencil.svg";
import reloadIcon from "../icon/reload.svg";
import searchIcon from "../icon/search.svg";
import FunBarForActionHistory from "./funBarForActionHistory.js";
import getUrl from '../function/getUrl'
import StudentCard from '../components/studentCard.js'

//學生學習進度一覽表
const url = getUrl()
const token = sessionStorage.getItem("token")
const SingleStudentView = (props) => {
  let param = useParams(); //功能項目
  let studentDBID = param.studentDBID;
  let studentEdit = "/studentEdit/" + studentDBID;
  let goLink = "";
  let total = 0; //
  let classCount = 0; //總購課數
  let cancel = 0; //取缺數
  let noshow = 0;//缺課
  let done = 0; //完課數
  let reservation = 0;

  const [actionList, setActionList] = useState(null);

  var imgStyle = {
    width: "20px",
    height: "20px",
  };
  var fontStyle = {
    fontSize: "0.5em",
  };

  function getStudentActionHistory() {
    fetch(`${url}/appGet/getStudentActionHistory?studentDBID=${studentDBID}&token=${token}`).then(res => {
      return res.json();
    }).then(json => {
      console.log(json)

      let actionArray = []
      let collapseArray = []
          
      json[0].map((item,index) => {
        let level = null
        switch (item["level"]) {
          case "傑出":
            level = <span className="text-info">{item["level"]}</span>
            break;
          case "待加強":
            level = <span className="text-danger">{item["level"]}</span>
            break;
          case "完成":
            level = <span className="text-muted">{item["level"]}</span>
            break;
          default:
            level = <span className="text-muted">{item["level"]}</span>

        }

        
        let singleCollapseArray=[]
        let singleCollapseArrayCount=0

        console.log(item["action"],json[1][item["action"]])
        if (json[1][item["action"]] ){
          json[1][item["action"]].map(levelItem=>{
            console.log(levelItem)
            singleCollapseArrayCount+=1
              let singleCollapse = (
                <tr className=" collapse" id={`collapseExample${index}`}  style={{backgroundColor:"#F9F9F9 "}} onClick={()=>{
                  window.location.href=`/singleClassFeedBack/${levelItem["classRecordDBID"]}`

                }}>
                 <th scope="row"></th>
                  <td>
                  </td>
                  <td></td>
                  <td></td>
                  <td className="text-muted"> <small>{levelItem["classDate"]}</small></td>
                  <td className="text-muted"><small>{levelItem["level"]}</small></td>
                </tr>
                )
              singleCollapseArray.push(singleCollapse)
            
          })
        }


       
        
        let singleClass = "";

        singleClass = (
          <>
          <tr  data-toggle="collapse" href={`#collapseExample${index}`} aria-expanded="false" aria-controls="collapseExample">
            <th scope="row">{item["week"]}</th>
            <td>
              {item["classProcess"]}
            </td>

            <td className=" text-center"  > {item["action"]} </td>
            <td className="text-muted "> {singleCollapseArrayCount}</td>
            <td> {item["classDate"]}</td>
            <td>{level}</td>
          
          </tr>
          {singleCollapseArray}
          </>
        );
        actionArray.push(singleClass)

       

      })
      setActionList(actionArray)
  

    })
  }

  useEffect(() => {

    getStudentActionHistory()
  }, []);

  return (
    <>
      <div className="container  text-center">

        <StudentCard studentDBID={studentDBID} />
       

        <div className="card alert-warning p-2 mt-1">
          <h5 className="m-1">學習歷程</h5>
        </div>
     

        <table className="table ">
          <thead>
            <tr className="text-center mt-3">
              <th scope="row">周次</th>
              <th scope="col">主題</th>
              <th scope="col">動作</th>
              <th scope="col">記錄筆數</th>
              <th scope="col">最後日期</th>
              <th scope="col">評級</th>
            </tr>
          </thead>
          <tbody className="text-center">{actionList}

          </tbody>
        </table>
      </div>
  
    </>
  );
};

export default SingleStudentView;
