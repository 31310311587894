import React, { useState, useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import birthdayToAge from "../function/birthdayToAge.js";
import getUrl from '../function/getUrl'
import reloadIcon from "../icon/reload.svg";
import searchIcon from "../icon/search.svg";
import checkLogin from "../function/checkLogin.js"
const url = getUrl()
const token = sessionStorage.getItem("token")
//個案清單
const GetReportList = (props) => {
  checkLogin()
  let param = useParams(); //功能項目
  let togo = param.togo;
  let title = "個案核備列表";
  let goLink = "/SingleStudentTrialView/";
  let level = sessionStorage.getItem("level")
  
  //設計功能切換

  //進度條
  let loadingState = (
    <>
      <h5 className="text-info mt-5">資料載入中...</h5>
      <div className="progress w-50">
        <div
          className="progress-bar progress-bar-striped progress-bar-animated bg-info"
          role="progressbar"
          aria-valuenow="75"
          aria-valuemin="0"
          aria-valuemax="100"
          style={{ width: "100%" }}
        ></div>
      </div>
    </>
  );
  const [reportList, setReportList] = useState(loadingState);

  var imgStyle = {
    width: "20px",
    height: "20px",
  };
  function handleSearch(e) {
    setReportList(loadingState);
    let name = document.getElementById("searchName").value;


    let reportArray = [];

    fetch(`${url}/appGet/reportList?name=${name}&token=${token}`)
      .then((res) => {
        return res.json();
      })
      .then((json) => {
        console.log(json)
        json.sort((a, b) => {
          var nameA = a.createDate;
          var nameB = b.createDate;
          if (nameA > nameB) {
            return -1;
          }
          if (nameA < nameB) {
            return 1;
          }
          return 0;
        });

        for (let x in json) {

          let singleStudent = (
            <tr

            >
              <td className="px-1">{json[x]["reportDBID"]}</td>
              <td className="px-1">{json[x]["createDate"].substring(0, 10)}...</td>
              <td className="px-1">{json[x]["reportType"]}</td>
              <td className="px-1">
                <button
                  type="button"
                  className="btn btn-sm btn-light dropdown-toggle  p-1"
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                >
                  {json[x]["context"].substring(0, 5)}...
                      </button>
                <div className="dropdown-menu">
                  <div className="dropdown-item">{json[x]["context"]}</div>
                </div>

              </td>

              <td className="px-1">{json[x]["name"]}</td>
              <td className="px-1">
                <button
                  type="button"
                  className="btn btn-sm btn-light dropdown-toggle  p-1"
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                >
                  {json[x]["comments"].substring(0, 5)}...
                      </button>
                <div className="dropdown-menu">
                  <div className="dropdown-item">{json[x]["comments"]}</div>
                </div>
              </td>
              <td className="px-1 " >{json[x]["result"]}</td>
              <td className="px-1 "  onClick={() => {

                if (level >= 3) {
                  window.location.href = `/ReportEdit/${json[x]["reportDBID"]}`
                }
                return
              }}><div className="btn alert-danger py-0">核議</div></td>

            </tr>
          );

          reportArray.push(singleStudent);

        }
      })
      .then(() => {
        setReportList(reportArray);
      });
  }


  useEffect(() => {
    handleSearch();
  }, []);

  return (
    <>
      <div className="container text-center">
        <div className="card alert-info p-2">
          <div className="container vw-75" style={{ maxWidth: "500px" }}>
            <h5 className="m-1">{title}</h5>

            <div className="card-body">
              <div className="input-group mb-3">
                <div className="input-group-prepend">
                  <span className="input-group-text" id="basic-addon1">
                    {" "}
                    <img src={searchIcon} style={imgStyle} alt="search" />{" "}
                    Search
                  </span>
                </div>
                <input
                  type="text"
                  className="form-control "
                  id="searchName"
                  placeholder="個案內容關鍵字"
                  aria-label="Username"
                  aria-describedby="basic-addon1"
                />
                <span> &nbsp; &nbsp; </span>
                <button className="btn btn-info w-10" onClick={handleSearch}>
                  <img src={reloadIcon} style={imgStyle} /> 送出
                </button>
              </div>
              <div></div>
            </div>
          </div>
        </div>

        <hr />

        <table class="table table-striped">
          <thead>
            <tr className="text-center">
              <th scope="col" className="px-1">編號</th>
              <th scope="col" className="px-1">建立日期</th>
              <th scope="col" className="px-1">個案類別</th>
              <th scope="col" className="px-1">個案描述</th>
              <th scope="col" className="px-1">申請人</th>
              <th scope="col" className="px-1">決議回覆</th>
              <th scope="col" className="px-1">狀態</th>
              <th scope="col" className="px-1">動作</th>


            </tr>
          </thead>
          <tbody className="text-center">{reportList}</tbody>
        </table>
      </div>
    </>
  );
};

export default GetReportList;
