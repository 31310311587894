import React, {
  useState,
  useEffect,
  useCallback,
  updateState,
  useReducer,
  useContext,
} from "react";

import getDay from "../function/getDay";
import getUrl from "../function/getUrl";
import BackupToBooking from "../components/backupToBooking";
import checkLogin from "../function/checkLogin.js";
import GetStudentTrialListForHome from "../pages/getStudentTrialListForHome";
import CoachDashBoard from "../pages/coachDashBoard.js";
import ReportListFromHome from "../components/reportListForHome";
import GetOverNightClassRecord from "../components/getOverNightClassRecord"

const Home = (props) => {
  const url = getUrl();
  const token = sessionStorage.getItem("token");
  const [orderTotal, setOrderTotal] = useState(0);
  const [productTotal, setProductTotal] = useState(0);
  const [coachData, setCoachData] = useState([]);
  const [homeData, setHomeData] = useState({});
  const [goalData, setGoalData] = useState(null);
  let today = getDay(0, "-");
  if (!checkLogin()) {
    window.location.href = "/logout";
  }

  useEffect(() => {
    fetch(`${url}/appGet/homeData?token=${token}`)
      .then((res) => {
        return res.json();
      })
      .then((json) => {
        let coachArray = {};
        json["coachResult"].map((item) => {
          let tempCoach = {};
          tempCoach["name"] = item.name;
          tempCoach["coachAmount"] = item.amount;
          coachArray[item.coachID] = tempCoach;
        });

        json["assistantResult"].map((item) => {
          let temp = coachArray[item.coachID];

          //oachArray[item.coachID]['assistantAmount']=item.amount
        });

        json["staffResult"].map((staff, index) => {
          json["coachResult"].map((item) => {
            if (item.coachID == staff.staffDBID) {
              json["staffResult"][index]["coachAmount"] = item.amount;
            }
          });

          json["assistantResult"].map((item) => {
            if (item.assistantID == staff.staffDBID) {
              json["staffResult"][index]["assistantAmount"] = item.amount;
            }
          });
          if (!staff.coachAmount) {
            json["staffResult"][index]["coachAmount"] = 0;
          }
          if (!staff.assistantAmount) {
            json["staffResult"][index]["assistantAmount"] = 0;
          }

          json["staffResult"][index]["total"] =
            parseInt(staff.coachAmount, "10") +
            parseInt(staff.assistantAmount, "10");
        });

        json["staffResult"].sort((a, b) => {
          var nameA = a.total;
          var nameB = b.total;
          if (nameA > nameB) {
            return -1;
          }
          if (nameA < nameB) {
            return 1;
          }
          return 0;
        });

        setCoachData(json["staffResult"]);
     
        let tempGoal = (
          
          <>

        <div className="d-block d-sm-none ">
          <table>
          <thead>
              <tr>
                <th className="p-1 text-info">本月目標</th>
                <th className="p-1 text-dark" cols="4"><h5>{json["thisMonthGoal"].goal}</h5></th>
              </tr>
              </thead>
          </table>
        
            <table className="text-center" style={{"width":"100%"}}>
               <tr>
                <td >
                  <tr><td>成長率</td></tr>
                            {
                json["thisMonthGoal"].goalDetail.map(data=>{
                  return (
                 
                  <tr > <td >{data.parcentage}%</td></tr>
              
                  )
                })
              }</td>
              
                <td >
                <tr ><td>目標</td></tr>
                  
                  {
                json["thisMonthGoal"].goalDetail.map(data=>{
                  return (
                   <tr><td className="text-center" >{data.goal}</td></tr>
           
                  )
                })
              }
              </td>
                </tr> 
           

           

              
             
          
            </table>
            </div>
            
            <div className="d-none d-sm-block">
          <table>
          <thead>
              <tr>
                <th className="p-1 text-info">本月目標</th>
                <th className="p-1 text-dark" cols="4">{json["thisMonthGoal"].goal}</th>
              </tr>
              </thead>
          </table>
        
            <table className="table table-striped p-1 mb-3 text-center">
          
          
              <tbody>

              <tr className="p-1 ">
                   <td className="p-1 border border-muted">成長率</td>
                 
                   {
                json["thisMonthGoal"].goalDetail.map(data=>{
                  return (<>
                 
                   <td className="p-1 border border-muted">{data.parcentage}%</td>
              
            
                </>
                  )
                })
              }
                </tr>
                <tr className="p-1 ">
                   <td className="p-1  border border-muted">目標</td>
                   {
                json["thisMonthGoal"].goalDetail.map(data=>{
                  return (
                   <td className="p-1 border border-muted  " >{data.goal}</td>
           
                  )
                })
              }
                </tr>
             
              </tbody>
            </table>
            </div>
          </>
        );

        setGoalData(tempGoal);

        // console.log(json['orderCmdResult'][0]['amount'])
        document.getElementById("orderAmount").innerHTML =
          json["orderCmdResult"][0]["amount"];
        document.getElementById("orderTotal").innerHTML =
          json["orderCmdResult"][0]["total"];
        document.getElementById("productAmount").innerHTML =
          json["productCmdResult"][0]["amount"];
        document.getElementById("productTotal").innerHTML =
          json["productCmdResult"][0]["total"];
        document.getElementById("trialOrderAmount").innerHTML =
          json["trialOrderCmdResult"][0]["amount"];
        document.getElementById("trialOrderTotal").innerHTML =
          json["trialOrderCmdResult"][0]["total"];
        json["trialCountResult"].map((item) => {
          switch (item.studentState) {
            case "劃位":
              document.getElementById("trialBooking").innerHTML = item.amount;
              break;
            case "完課":
              document.getElementById("trialDone").innerHTML = item.amount;
              break;
          }
        });

        json["classCountResult"].map((item) => {
          switch (item.studentState) {
            case "劃位":
              document.getElementById("classBooking").innerHTML = item.amount;
              break;
            case "完課":
              document.getElementById("classDone").innerHTML = item.amount;
              break;
            case "取消":
              document.getElementById("classCancel").innerHTML = item.amount;
              break;
            case "融通取消":
              document.getElementById("cancel").innerHTML = item.amount;
              break;
          }
        });
        setHomeData(json);
      });
  }, []);
  //Home
  return (
    <>
      <div className="card" style={{ width: "18rem;" }}>
        <div className="card-body alert-info">
          <div className="row">
            <div className="col-12 col-lg-6  ">
              <h5 className="card-title">營業指標</h5>
              <div className="card-text alert-light rounded p-2">
                {goalData}
                <table className="table table-striped">
                  <thead>
                    <tr>
                      <th style={{ width: "40%" }}>項目</th>
                      <th style={{ width: "40%" }}>指標</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr
                      onClick={() => {
                        window.location.href = "/orderListViewMonth";
                      }}
                    >
                      <td>課程銷售</td>
                      <td>
                        訂單:<span id="orderAmount"></span>筆 $
                        <span id="orderTotal"></span>
                      </td>
                    </tr>
                    <tr>
                      <td>商品銷售</td>
                      <td>
                        訂單:<span id="productAmount"></span>筆 $
                        <span id="productTotal"></span>
                      </td>
                    </tr>
                    <tr>
                      <td>體驗付費</td>
                      <td>
                        訂單:<span id="trialOrderAmount"></span>筆 $
                        <span id="trialOrderTotal"></span>
                      </td>
                    </tr>

                    <tr>
                      <td>正式課程</td>
                      <td>
                        劃位:<span id="classBooking"></span> / 完課:
                        <span id="classDone">0</span> / 取消:
                        <span id="classCancel">0</span>
                      </td>
                    </tr>

                    <tr>
                      <td>體驗課程</td>
                      <td>
                        劃位:<span id="trialBooking"></span> / 完課:
                        <span id="trialDone">0</span>
                      </td>
                    </tr>
                    <tr>
                      <td>融通取消</td>
                      <td>
                        <span id="cancel">0</span>筆
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>

            <div className="col-12 col-lg-6  ">
              <CoachDashBoard getMonth={today}/>
            </div>
          </div>
        </div>
      </div>

      {/**-----以下為 通知 個案申請 */}
      {/* <ReportListFromHome /> */}
 {/**-----以下為 隔天仍然處理的劃位 */}
  <div className="d-none d-xl-block d-md-block">
      <GetOverNightClassRecord />
      {/**-----以下為 通知 課程有候補 但是多出空位者 */}
      <BackupToBooking />

      {/**---------以下為 體驗待追蹤 */}
      
        <GetStudentTrialListForHome />
      </div>
    </>
  );
};

export default Home;
