import React, {
  useState,
  useEffect,
} from "react";

import getDay from "../function/getDay";
import getUrl from "../function/getUrl";

import checkLogin from "../function/checkLogin.js";


const BackupToBooking = (props) => {
  const url = getUrl();
  const token = sessionStorage.getItem("token");
  const [classData,setClassData]=useState(null)
  if (!checkLogin()) {
    window.location.href = "/logout";
  }
  var day_list = ['日', '一', '二', '三', '四', '五', '六'];

  
  const getBackupToBooking = async () => {
    let result = await fetch(`${url}/appGet/backupToBooking?token=${token}`)
      .then((res) => {
        return res.json();
      }).then(result=>{

        let classArray=[]
    for (let x in result){
      let dateWeek = new Date(result[x].classDate).getDay()
      let week= day_list[dateWeek]
      let single=<tr className="text-center" onClick={()=>{window.location.href = `/SingleClassView/${result[x].classDBID}`}}>
      <td>{result[x].classDBID}</td>
      <td>{result[x].classDate}</td>
      <td>{week}</td>
      <td>{result[x].classTime}</td>
      <td>{result[x].age}</td>
      <td>{result[x].limit}</td>
      <td>{result[x].booking}</td>
      <td>{result[x].backUP}</td>
      <td>{result[x].cancel}</td>
    </tr>

      classArray.push(single)
    }

    return classArray
        
      })
      console.log(result)
      setClassData(result)
  };
 

  useEffect(() => {
   getBackupToBooking()

  },[])

  if (classData== null || classData== ""){
    return null
  }
  return (
    <>
      <div className="card mt-2" style={{ width: "18rem;" }}>
        <div className="card-body alert-danger">
          <h5 className="card-title">候補名單待處理 即時資訊</h5>
          <div className="card-text alert-light rounded p-2">
            <table className="table ">
              <thead>
                <tr className="text-center">
                  <th scope="col">編號</th>
                  <th scope="col">日期</th>
                  <th scope="col">星期</th>
                  <th scope="col">時間</th>
                  <th scope="col">年紀</th>
                  <th scope="col">上限</th>
                  <th scope="col">劃位</th>
                  <th scope="col">候補</th>
                  <th scope="col">取消</th>
                </tr>
              </thead>
              <tbody>
                {classData}

              </tbody>
            </table>
          </div>
        </div>
      </div>


    </>
  );
};

export default BackupToBooking;
