
import React ,{useState,useEffect} from 'react';
import checkLogin from "../function/checkLogin.js"
import getDay from "../function/getDay"
import getUrl from '../function/getUrl'


const url=getUrl()
const   Login=()=> {

    sessionStorage.clear();
      const [phone,setPhone]=useState('');
      const [password,setPassword]=useState('');
      const [error,setError]=useState('')
      
      function haneleAccountChange(e){
        setPhone(e.target.value)
    
        };
    
      function handlePassword(e){
        setPassword(e.target.value)
    
      };

        function hitknow2Login(e){
                fetch(`${url}/login?phone=${phone}&password=${password}`,{
                    mode: 'cors',
                }).then(res => {
                    return res.json();
                }).then(json => {
                        console.log('获取的结果', json);
                      
                        if (json.state==true){
                            sessionStorage.setItem('token',json.token);
                            sessionStorage.setItem('staffDBID',json.staff.staffDBID)
                            sessionStorage.setItem('name',json.staff.name)
                            sessionStorage.setItem('coach',json.staff.coach)
                            sessionStorage.setItem('level',json.staff.level)
                            //sessionStorage.setItem('shouldHit',json.shouldHit)
                            let timeCode=new Date().getTime()+1000*60*60*3
                             //let timeCode=time.setTime(time+1000*60*60)
                            sessionStorage.setItem('timeCode',timeCode)
                            window.location.href = "/home"
                         
                        }else{
                            setError(json.result)
                            setPhone('')
                            setPassword('')
                        }
                        
        })
    }
    
        return (
        <>
        <div className="container m-3" >
            <div role="alert" color="warning">
            <div style={{textAlign:'center'}}><h4 className="py-3">好好游 管理系統</h4></div>
           
            
    
            
        <div className="mt-3 card mr-auto ml-auto" style={{maxWidth:"500pt"}}>
      
                <div className="card-body">
                   
                    <form>
                    <div className="card mb-2">
                        <div className="card-body alert-info  border-1 p-2 text-center">好好游(股)公司<br/>個人資料保護法維護公告</div>
                         <div className="card-body border-1" style={{width:"100%"}}>
                         
    顧客基於對品牌與店內夥伴的信任，於快好好游(股)公司旗下相關品牌 〈好好游〉所填寫之個人資料，僅供店內消費服務時使用，同時顧客的個資是受到個人資料保護法之保護，因此嚴禁夥伴在未經顧客同意下另做處理或利用，以免違反個人資料保護法。<br/>
    您登入即代表同意本公告之內容，並同意善盡相關約定。
                         </div>
                         </div> 
                         <h5 className="card-title">員工登入</h5>
                        <div className="formGroup">
                            <label>手機帳號
                            <input type="text" name="phone" id="phone"  className="form-control" style={{width:"100%"}}  value={phone}  onChange={haneleAccountChange}  />
                            <small id="emailHelp" className="form-text text-muted">請輸入ERP帳號</small>
                            </label>
                        </div>
                        <div className="formGroup">
                            <label >密碼
                            <input type="password" name="password" value={password}  style={{width:"100%"}} id="examplePassword" className="form-control"  onChange={handlePassword}  />
                            </label>
                        </div>
                       
                        <br/>
                        <div  className="formGroup text-center">
                        <div className="btn btn-info " type="button" style={{width:"70%"}}  onClick={hitknow2Login} > 登入 Good2swimOS3</div>
                       
                        </div>
           
                     </form>
                </div>
        </div>
        
        <div className="formGroup text-center"><h5>{error}</h5></div>
  
        </div>
        </div>
        </>
        );
        }




export default Login;