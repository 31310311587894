import React from "react";
// import { Link, useParams } from "react-router-dom";

//Icon

import logo from "../icon/good2swim-logo.png";
// import HomeSVG from "../icon/house.svg";
// import eject from "../icon/eject.svg";
// import PersonAdd from "../icon/people.svg";
// import birthdayCake from "../icon/birthdaycake.svg";
// import adminIcon from "../icon/admin.svg";

//功能列
const Navbar = (props) => {
  // var imgStyle = {
  //   width: "30px",
  //   height: "30px",
  // };

  // var fontStyle = {
  //   fontSize: "0.5em",
  // };

  let env = props.env;

  
  return (
    <nav className="navbar navbar-light text-light btn-dark ">
      <img src={logo} alt="logo" style={{ weight: "35px", height: "35px" }} />

      <h6>Good2Swim V3.01</h6>
    </nav>
  );
};

export default Navbar;
