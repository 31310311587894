import React, {
  useState,
  useEffect,
  useCallback,
  updateState,
  useReducer,
  useContext,
  createContext,
} from "react";
import { createStore } from "redux";
import { Link, useParams } from "react-router-dom";
import getUrl from "../function/getUrl.js";
import cancelIcon from "../icon/cancelWhite.svg";
import classIcon from "../icon/swimClass.svg";
import saveIcon from "../icon/save.svg";
import reloadIcon from "../icon/reload.svg";
import searchIcon from "../icon/search.svg";
import birthdayToAge from "../function/birthdayToAge.js";
import getDay from "../function/getDay.js";
import dateDiff from "../function/dateDiff.js";
import pencil_gray from "../icon/pencil_gray.svg";
import pencilIcon from "../icon/pencil.svg";
import getStudentState from "../function/getStudentState.js" //處理學員劃課狀態


var imgStyle = {
  width: "30px",
  height: "30px",
};

const url = getUrl();
const token = sessionStorage.getItem("token");
//主元件
const ClassStudentListViewComponent = (props) => {
  const classDBID = props.classDBID;
  const [studentList, setStudentList] = useState([]);
  const [viewUpdateState, setViewUpdateState] = useState(false);
  const [classData, setClassData] = useState({});
  const [classDate, setClassDate] = useState(null);
  const [classAge,setClasAge]=useState(null)
  
  useEffect(() => {

    //取得簽名與請假証明
    const getClassRecordSignPicAndProveData = (classDBID) => {
      return fetch(
        `${url}/appGet/getClassRecordSignPicAndProve?classDBID=${classDBID}&token=${token}`
      )
        .then((res) => {
          return res.json();
        })
        .then((json) => {
          return json;
        });
    };

    const getStudentData = (classDBID) => {
      return fetch(
        `${url}/appGet/singleClassView?classDBID=${classDBID}&token=${token}`
      )
        .then((res) => {
         
          return res.json();
        })
        .then((json) => {
          // console.log(json)
          return json;
        });
    };



    const goGetInit = async (classDBID) => {
      let signPicAndProveData = await getClassRecordSignPicAndProveData(classDBID);
      let data = await getStudentData(classDBID);
      setClassData(data);
      setClassDate(data.classDate);
      // console.log(data.age)
      setClasAge(data.age)
      data.studentList.map((item, index) => {
        // console.log(item.classRecordDBID)
        if (!data.studentList[index]["updateFlag"]) {
          data.studentList[index]["updateFlag"] = false; //是否更新過
        }
        if (!data.studentList[index]["newRecord"]) {
          data.studentList[index]["newRecord"] = false; //是否為新增
        }
       
        //處理簽名資料
       let signPicResult=signPicAndProveData.signPicArray.find(signItem=>{
         return signItem.classRecordDBID==item.classRecordDBID
       })
      //  console.log("signPicResult",signPicResult,item.classRecordDBID)
       data.studentList[index]["signPic"]=signPicResult.signPic

      //處理請假資料
       let proveResult=signPicAndProveData.proveArray.find(proveItem=>{
        return proveItem.classRecordDBID==item.classRecordDBID
      })
      // console.log("proveResult",proveResult)
      data.studentList[index]["prove"]=proveResult.prove

      });
      // console.log('studentList',data.studentList)
      setStudentList(getStudentState(data.studentList, data.classDate,setViewUpdateState,setStudentList));
    };
    goGetInit(classDBID);
    
  }, []);

  useEffect(()=>{
  
    //所有人的最後有效日與可劃位數
    fetch(`${url}/appGet/getAllStudentState?token=${token}`)
    .then((res) => {
      return res.json();
    })
    .then((json) => {
      console.log("get studtn all State 1")
      // getAllStudentState = json;
      sessionStorage.setItem("allStudentState",JSON.stringify(json))
      // console.log('getAllStudentState', getAllStudentState)
    });
  },0)

  const SaveStudentList = () => {
    function handelSave() {
      const operator = sessionStorage.getItem("staffDBID"); //未來請填入操作者 userID
      
      studentList.map((item, index) => {
        if (item.updateFlag) {
          console.log(item);
          const cmdUrl = `${url}/appGet/makeClassRecord?token=${token}&name=${item.name}&studentDBID=${item.studentDBID}&classDBID=${classDBID}&studentType=${item.studentType}&trialPayState=${item.trialPayState}&studentState=${item.studentState}&operator=${operator}&newRecord=${item.newRecord}&classRecordDBID=${item.classRecordDBID}`;

          fetch(cmdUrl)
            .then((res) => res.json())
            .catch((err) => {
              console.error("error:", err);
            })
            .then((res) => {
              console.log("success:", res);
            });
        }
      });

     window.location.href = `/SingleClassView/${classDBID}`;
    }
    let result = null;
    if (viewUpdateState) {
      result = (
        <>
          <button
            className="btn btn-info  mr-1 border-1 border-white"
            onClick={() => {
              let today = getDay(0, "-");
              window.location.href = `/SingleClassView/${classDBID}`;
            }}
          >
            <img src={cancelIcon} style={imgStyle} /> 取消
          </button>
          <button
            type="button"
            className="btn btn-danger border-1 border-white"
            onClick={handelSave}
          >
            <img src={saveIcon} style={imgStyle} alt="Home" /> 完成
          </button>
        </>
      );
    }
    return result;
  };

  function PickStudent(props) {
    return (
      <>
        <button
          type="button"
          className="btn btn-info border-1 border-white"
          data-toggle="modal"
          data-target="#exampleModalCenter"
        >
          <img src={pencilIcon} style={imgStyle} alt="Home" /> 學員劃位
        </button>

        {/*<!-- Modal -->8*/}

        <div
          className="modal fade"
          id="exampleModalCenter"
          tabIndex="-1"
          role="dialog"
          aria-labelledby="exampleModalCenterTitle"
          aria-hidden="true"
        >
          <div
            className="modal-dialog "
            role="document"
            style={{ minWidth: "800px", width: "80%" }}
          >
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title" id="exampleModalCenterTitle">
                  挑選學員
                </h5>
                <button
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div className="modal-body">
                <GetStudentListComponent
                  studentList={props.studentList}
                  classDate={classDate}
                />
              </div>
              <button
                type="button"
                className="btn btn-secondary"
                data-dismiss="modal"
              >
                Close
              </button>
            </div>
          </div>
        </div>
      </>
    );
  }

  function PickStudentTrial(props) {
    return (
      <>
        <button
          type="button"
          className="btn btn-success border-1 border-white ml-3 mr-3"
          data-toggle="modal"
          data-target="#exampleModalCenterTrial"
        >
          <img src={pencilIcon} style={imgStyle} alt="Home" /> 體驗劃位
        </button>

        {/*<!-- Modal -->8*/}

        <div
          className="modal fade"
          id="exampleModalCenterTrial"
          tabIndex="-1"
          role="dialog"
          aria-labelledby="exampleModalCenterTitle"
          aria-hidden="true"
        >
          <div
            className="modal-dialog "
            role="document"
            style={{ minWidth: "800px", width: "80%" }}
          >
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title" id="exampleModalCenterTitle">
                  挑選學員
                </h5>
                <button
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div className="modal-body">
                <GetStudentListComponentTrial
                  studentList={props.studentList}
                  classDate={classDate}
               
                />
              </div>
              <button
                type="button"
                className="btn btn-secondary"
                data-dismiss="modal"
              >
                Close
              </button>
            </div>
          </div>
        </div>
      </>
    );
  }

  const GetStudentListComponent = (props) => {
    
    let classDate = props.classDate;
    let title = "";
    let trial=props.trial
    
    let getDataURL = `${url}/appGet/getStudentByName`;

    //進度條
    let loadingState = (
      <>
        <h5 className="text-info mt-5">資料載入中...</h5>
        <div className="progress w-50">
          <div
            className="progress-bar progress-bar-striped progress-bar-animated bg-info"
            role="progressbar"
            aria-valuenow="75"
            aria-valuemin="0"
            aria-valuemax="100"
            style={{ width: "100%" }}
          ></div>
        </div>
      </>
    );
    const [studentListInside, setStudentListInside] = useState(null);

    var imgStyle = {
      width: "20px",
      height: "20px",
    };

    //判斷是否可以選課
    //所有人的最後有效日與可劃位數
    let getAllStudentState=JSON.parse(sessionStorage.getItem('allStudentState'))
    //捉取所有人的餘課數與最後有效日期
    // let getAllStudentState = {};
    // fetch(`${url}/appGet/getAllStudentState?token=${token}`)
    //   .then((res) => {
    //     return res.json();
    //   })
    //   .then((json) => {
    //     console.log("get studtn all State 1")
    //     getAllStudentState = json;
    //     // console.log('getAllStudentState', getAllStudentState)
    //   });

    //檢查是否可以選課
    //條件 有餘課數  && 有效期限內 且為正式生
    function checkStudent(studentDBID) {
      let getAllStudentState=JSON.parse(sessionStorage.getItem('allStudentState'))
      let classDataTemp = classData;
      // console.log("classDataTemp.classDate", classDataTemp.classDate);
      let today = getDay(0, "-");
      // console.log('getAllStudentState',studentDBID,getAllStudentState[studentDBID])
      if (getAllStudentState[studentDBID]) {
        //console.log(today, getAllStudentState[studentDBID])

        //卡時間 限製為 2021/07/15 前不設控

        // if (today <= "2021-12-31" &&  getAllStudentState[studentDBID]["amount"] > 0 ){
        //   return true
        // }

         //設控：還可以劃位/期限不得超過有效期限
         let thisClassIsELP=JSON.parse(sessionStorage.getItem('thisClassIsELP'))
        if (thisClassIsELP){
          if( getAllStudentState[studentDBID]["amountELP"] > 0
          && getAllStudentState[studentDBID]["lastDate"] >= today  &&
          getAllStudentState[studentDBID]["lastDate"] >= classDataTemp.classDate ){
            return true
          }else{
            return false
          }
         }

        if(
         
          getAllStudentState[studentDBID]["amount"] > 0
          && getAllStudentState[studentDBID]["lastDate"] >= today  &&
          getAllStudentState[studentDBID]["lastDate"] >= classDataTemp.classDate 

        ) {
          return true;
        }
      }
    }

    function handleSearch(e) {
      // setStudentListInside(loadingState);
      // setStudentListInside(null);
      let getAllStudentState=JSON.parse(sessionStorage.getItem('allStudentState'))
      let name = document.getElementById("searchName").value;
      let studentArray = [];
      let studentListSet = new Set();
      console.log("studentList", studentList);
      for (let z in studentList) {
        console.log(studentList[z].studentDBID);
        studentListSet.add(studentList[z].studentDBID);
      }
      console.log(studentListSet);
      console.log(
        "${url}/appGet/getStudentByName?name=${name}&$token=${token}",
        `${url}/appGet/getStudentByName?name=${name}&$token=${token}`
      );
      fetch(`${url}/appGet/getStudentByName?name=${name}&token=${token}`)
        .then((res) => {
          return res.json();
        })
        .then((json) => {
          console.log(json);
          for (let x in json) {
            //已劃課名單沒有出現 且有餘課數 且課程日期 早於有效日期
            if (
              !studentListSet.has(json[x]["studentDBID"]) &&
              checkStudent(json[x].studentDBID)
            ) {
              let lastDate = (
                <span>{getAllStudentState[json[x]["studentDBID"]].lastDate}</span>
              );
              let amount = (
                <span>{getAllStudentState[json[x]["studentDBID"]].amount}</span>
              );

              let thisClassIsELP=JSON.parse(sessionStorage.getItem('thisClassIsELP'))
              if (thisClassIsELP){
                amount = ( <span>ELP:  {getAllStudentState[json[x]["studentDBID"]].amountELP}</span>)
              }
              let age = birthdayToAge(json[x]["birthday"], "/");
              let singleStudent = (
                <tr
                  onClick={() => {
                    json[x].updateFlag = true;
                    json[x].newRecord = true;
                    json[x].studentState = "";
                    json[x].signPic = "";
                    json[x].prove = "";
                   

                    setStudentListInside(null);
                    document.getElementById("searchName").value = null;
                    console.log(json[x]);
                    console.log(studentList);
                    let studentListTemp = [];
                    for (let z in studentList) {
                      studentListTemp.push(studentList[z]);
                    }

                    studentListTemp.push(json[x]);

                    setViewUpdateState(true);
                    setStudentList(getStudentState(studentListTemp, classDate,setViewUpdateState,setStudentList));
                    // setUpdateState(true)
                  }}
                  className="text-drak"
                  data-dismiss="modal"
                  style={{ textDecoration: "none" }}
                  key={x}
                >
                  <th scope="row">{parseInt(x, 10) + 1}</th>
                  <th scope="row">{json[x]["name"]}</th>
                  <th>{json[x]["nickName"]}</th>
                  <th>{json[x]["studentType"]}</th>
                  <th>{age[0]}</th>
                  <th>{json[x]["parent"]}</th>
                  <th>{amount}</th>
                  <th>{lastDate}</th>
                </tr>
              );
              studentArray.push(singleStudent);
            }else if(json[x]["studentType"]=="正式"){
              try{
              let lastDate = (
                <span>{getAllStudentState[json[x]["studentDBID"]].lastDate}</span>
              );
              let amount = (
                <span>{getAllStudentState[json[x]["studentDBID"]].amount}</span>
              );
              let age = birthdayToAge(json[x]["birthday"], "/");
              let singleStudent = (
                <tr
                  
                  className="text-muted"
                  data-dismiss="modal"
                  style={{ textDecoration: "none" }}
                  key={x}
                >
                  <th scope="row">{parseInt(x, 10) + 1}</th>
                  <td>{json[x]["name"]}</td>
                  <td>{json[x]["nickName"]}</td>
                  <td>{json[x]["studentType"]}</td>
                  <td>{age[0]}</td>
                  <td>{json[x]["parent"]}</td>
                  <td>{amount}</td>
                  <td>{lastDate}</td>
                </tr>
              );
              studentArray.push(singleStudent);
            }catch{

            }
            }
          }
        })
        .then(() => {
          setStudentListInside(studentArray);
        });
    }

    
    return (
      <>
        <div className="container text-center mt-3">
          <div className="card alert-info p-2 ">
            <div className="container vw-75" style={{ maxWidth: "1200px" }}>
              <h5 className="m-1">{title}</h5>

              <h6 className="m-1 text-muted">挑選學員</h6>
              <div className="card-body">
                <div className="input-group mb-2">
                  <div className="input-group-prepend">
                    <span className="input-group-text" id="basic-addon1">
                      {" "}
                      <img
                        src={searchIcon}
                        style={imgStyle}
                        alt="search"
                      />{" "}
                      Search
                    </span>
                  </div>
                  <input
                    type="text"
                    className="form-control "
                    id="searchName"
                    placeholder="學員、家長姓名"
                    aria-label="Username"
                    aria-describedby="basic-addon1"
                  />
                  <button className="btn btn-info w-20" onClick={handleSearch}>
                    <img src={reloadIcon} style={imgStyle} /> 送出
                  </button>
                </div>
              </div>
              <div></div>
            </div>
          </div>

          <hr />

          <table className="table table-striped">
            <thead>
              <tr className="text-center">
                <th scope="row">#</th>
                <th scope="col">姓名</th>
                <th scope="col">暱稱</th>
                <th scope="col">身分</th>
                <th scope="col">年紀</th>
                <th scope="col">家長</th>
                <th scope="col">可劃數</th>
                <th scope="col">有效期限</th>
              </tr>
            </thead>
            <tbody className="text-center">{studentListInside}</tbody>
          </table>
        </div>
      </>
    );
  };

  const GetStudentListComponentTrial = (props) => {
    
    let classDate = props.classDate;
    let title = "";
    let trial=props.trial
    
    let getDataURL = `${url}/appGet/getStudentByNameTrial`;

    //進度條
    let loadingState = (
      <>
        <h5 className="text-info mt-5">資料載入中...</h5>
        <div className="progress w-50">
          <div
            className="progress-bar progress-bar-striped progress-bar-animated bg-info"
            role="progressbar"
            aria-valuenow="75"
            aria-valuemin="0"
            aria-valuemax="100"
            style={{ width: "100%" }}
          ></div>
        </div>
      </>
    );
    const [studentListInside, setStudentListInside] = useState(null);

    var imgStyle = {
      width: "20px",
      height: "20px",
    };



   

    function handleSearchTrial(e) {
      setStudentListInside(loadingState);
      let name = document.getElementById("searchNameTrial").value;
      let studentArray = [];
      let studentListSet = new Set();
      console.log("studentList", studentList);
      for (let z in studentList) {
        console.log(studentList[z].studentDBID,studentList[z].name,studentList[z].studentType);
        studentListSet.add(studentList[z].studentDBID);
      }
      console.log(studentListSet);
      // console.log(
      //   "${url}/appGet/getStudentByNameTrial?name=${name}&$token=${token}",
      //   `${url}/appGet/getStudentByNameTrial?name=${name}&$token=${token}`
      // );
      fetch(`${url}/appGet/getStudentByNameTrial?name=${name}&token=${token}`)
        .then((res) => {
          return res.json();
        })
        .then((json) => {
          console.log(`${url}/appGet/getStudentByNameTrial?name=${name}&token=${token}`)
          console.log(json);
          for (let x in json) {
           //處理 身分為體驗
           if (!studentListSet.has(json[x]["studentDBID"])){
              
              let age = birthdayToAge(json[x]["birthday"], "/");
              let singleStudent = (
                <tr
                  onClick={() => {
                    json[x].updateFlag = true;
                    json[x].newRecord = true;
                    json[x].studentState = "";
                    json[x].signPic = "";
                    json[x].prove = "";
                    json[x].studentType="體驗"
                    json[x].studentKind="體驗"
                    setStudentListInside(null);
                    document.getElementById("searchName").value = null;
                    // console.log(json[x]);
                    // console.log(studentList);
                    let studentListTemp = [];
                    for (let z in studentList) {
                      // console.log(studentList[z])
                      studentListTemp.push(studentList[z]);
                    }

                    studentListTemp.push(json[x]);

                    setViewUpdateState(true);
                    setStudentList(getStudentState(studentListTemp, classDate,setViewUpdateState,setStudentList));
                    // setUpdateState(true)
                  }}
                  className="text-muted"
                  data-dismiss="modal"
                  style={{ textDecoration: "none" }}
                  key={x}
                >
                  <th scope="row">{parseInt(x, 10) + 1}</th>
                  <td>{json[x]["name"]}</td>
                  <td>{json[x]["nickName"]}</td>
                  <td>{json[x]["studentType"]}</td>
                  <td>{age[0]}</td>
                  <td>{json[x]["parent"]}</td>
                  <td>{json[x]["orderDate"]}</td>
                </tr>
              );
              studentArray.push(singleStudent);
            }
         
          }
        })
        .then(() => {
          setStudentListInside(studentArray);
        });
    }

   
    return (
      <>
        <div className="container text-center mt-3">
          <div className="card alert-info p-2 ">
            <div className="container vw-75" style={{ maxWidth: "1200px" }}>
              <h5 className="m-1">{title}</h5>

              <h6 className="m-1 text-muted">挑選體驗學員</h6>
              <div className="card-body">
                <div className="input-group mb-2">
                  <div className="input-group-prepend">
                    <span className="input-group-text" id="basic-addon1">
                      {" "}
                      <img
                        src={searchIcon}
                        style={imgStyle}
                        alt="search"
                      />{" "}
                      Search
                    </span>
                  </div>
                  <input
                    type="text"
                    className="form-control "
                    id="searchNameTrial"
                    placeholder="學員、家長姓名"
                    aria-label="Username"
                    aria-describedby="basic-addon1"
                  />
                  <button className="btn btn-info w-20" onClick={handleSearchTrial}>
                    <img src={reloadIcon} style={imgStyle} /> 送出
                  </button>
                </div>
              </div>
              <div></div>
            </div>
          </div>

          <hr />

          <table className="table table-striped">
            <thead>
              <tr className="text-center">
                <th scope="row">#</th>
                <th scope="col">姓名</th>
                <th scope="col">暱稱</th>
                <th scope="col">身分</th>
                <th scope="col">年紀</th>
                <th scope="col">家長</th>
                <th scope="col">體驗付費日期</th>
              </tr>
            </thead>
            <tbody className="text-center">{studentListInside}</tbody>
          </table>
        </div>
      </>
    );
  };


  function UploadProve(props) {
      let classRecordDBID=props.classRecordDBID
                               
    
    const [proveFile, setProveFile] = useState(null);
    if (classRecordDBID==null){
        return null
    }
    return (
      <>
        <div
          className="btn alert-dark "
          data-toggle="modal"
          data-target={`#UploadProve${classRecordDBID}`}
        >
          上傳
        </div>

        {/*<!-- Modal -->8*/}

        <div
          className="modal fade"
          id={`UploadProve${classRecordDBID}`}
          tabIndex="-1"
          role="dialog"
          aria-labelledby="exampleModalCenterTitle"
          aria-hidden="true"
        >
          <div
            className="modal-dialog text-center"
            role="document"
            style={{ minWidth: "600px", width: "80%" }}
          >
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title" id="exampleModalCenterTitle">
                  請假証明文件
                </h5>
                <button
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div className="modal-body">
                <div className="card ">
                  <div className="card-header">上傳文件{classRecordDBID}</div>
                  <form className="form">
                    <div className="card-body">
                      <div className="m-1">
                        <div className="input-group">
                          <div className="input-group-prepend">
                            <span
                              className="input-group-text alert-info"
                              id="validationTooltipUsernamePrepend"
                            >
                              上傳圖片
                            </span>
                          </div>
                          <input
                            type="file"
                            id="proveFile"
                            accept=".png,.jpg"
                            className="form-control"
                            aria-describedby="validationTooltipUsernamePrepend"
                            required
                            onChange={(e) => {
                              var fileReader = new FileReader();
                              fileReader.readAsDataURL(e.target.files[0]);
                              fileReader.onload = () => {
                                // console.log(fileReader.result);
                                // console.log(classRecordDBID)
                                setProveFile(fileReader.result);
                               
                                document.getElementById('proveSaveBtn').disabled=false
                              };

                              fileReader.onerror = () => {
                              
                                setProveFile(null);
                                document.getElementById('proveSaveBtn').disabled=true
                              };
                            }}
                          />
                        </div>
                        <div className="card mt-1">
                          <img className="border-1 m-2 p-2" src={proveFile} width="300px" />
                        </div>

                        <div className="card mt-1">
                         

                          <div
                          id="proveSaveBtn"
                            className="btn btn-danger p-1 m-2 "
                            
                            disabled

                            onClick={()=>{
                               
                                var formData = new FormData();
                                formData.append("classRecordDBID", classRecordDBID)
                                formData.append("prove", proveFile)
                                formData.append("operator", sessionStorage.getItem('staffDBID'));
                                formData.append("token", token)
                                fetch(`${url}/appPost/uploadProve`, {
                                  method: "POST",
                                  body: formData,
                                  mode: "cors",
                                }).then(res=>{
                                   window.location.href=`/SingleClassView/${classDBID}`
                                })

                            }}
                          >
                            Save
                            
                          </div>

                     
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }

  

  return (
    <>
      <div className="card p-2 mt-2 border-1  vw-75">
        <div
          className="card  border-1 alert-info vw-75 text-center"
          id="getStudentList"
          available="false"
        >
          <div className=" p-1" align="right">
          <PickStudentTrial  studentList={studentList} />
            <PickStudent studentList={studentList} />
         
          </div>
  
        </div>
        <table className="table table-striped text-center vw-75">
          <thead>
            <tr>
              <th scope="row">#</th>
              <th scope="col">學員編號</th>
              <th scope="col">姓名</th>
              <th scope="col">暱稱</th>
              <th scope="col">身分</th>
              <th scope="col">手機</th>
              <th scope="col">年紀</th>
              <th scope="col">狀態</th>
              <th scope="col">請假文件</th>
              <th scope="col">簽到</th>
              <th scope="col">更新</th>
              <th scope="col">操作</th>
            </tr>
          </thead>
          <tbody>
            {studentList.map((item, index) => {
                 let today = getDay(0, "-");
                 //let classDate = classData.classDate
                //  console.log("classDate", classDate);
                //  console.log(item)
                 let dayDiff = dateDiff(classDate, today); // 日期差
                let proveUpload=<td></td>
                if (item["prove"] != "") {
                    // proveUpload= <td><div className="btn alert-info" onClick={()=>{alert( <img src={searchIcon} width="100px" />)}}>影像</div></td>
                    proveUpload = (
                      <td >
                       <img src={item.prove} width="100pt" />
                      </td>
                    );
                }else if (dayDiff <=2 && (item.studentState=="劃位" ||item.studentState=="缺課"||item.studentState=="融通取消") ){
            
                proveUpload = (
                    <td>
                      <UploadProve classRecordDBID={item.classRecordDBID}/>
                    </td>
                  );
                  // console.log(item["originalStudentState"])
                  
              
         }
         switch (item["originalStudentState"]){
          case "劃位":
            studentList[index]["originalStudentState"]=<span className="  border-danger alert-info">劃位</span>
            break;
            case "候補":
              studentList[index]["originalStudentState"]=<span className="border-warning alert-warning">候補</span>
            break;
        }
       
        if (item["studentType"]=="體驗"){
          studentList[index]["studentTypeShow"]=<span className="border-warning alert-success">體驗</span>
        }else{
          studentList[index]["studentTypeShow"]=<span className="text-muted">正式</span>
        }

              let age=birthdayToAge(item['birthday'],"/")
              let showAge=<span>{age[0]}</span>
              if (age[1]!=classAge){
                showAge=<span className="text-danger">{age[0]}</span>
              }


              let toGoToStudentView = "/SingleStudentView/" + item["studentID"];
              let singleStudent = (
                <tr className="text-muted" key={index + 1}>
                  <td>{index + 1}</td>
                  <td scope="col">
                    <Link className="text-muted" to={toGoToStudentView}>
                      {item["studentDBID"]}
                    </Link>
                  </td>
                  <td
                    scope="col"
                    onClick={() => {
                      window.location.href = `/SingleStudentView/${item["studentDBID"]}`;
                    }}
                  >
                    <span className="btn alert-info">{item["name"]} </span>
                  </td>
                  <td scope="col">{item["nickName"]}</td>
                  <td scope="col">{item["studentTypeShow"]}</td>
                  <td scope="col">{item["phone"]}</td>
                  <td scope="col">{showAge}</td>
                  <td scope="col">{item["originalStudentState"]}</td>
                  {proveUpload}
                  <td>
                    <img src={item.signPic} width="100px" />
                  </td>
                  <td> {item["select"]}</td>
                  
                  <td scope="col"> <small>{item["operatorName"]}</small></td>
                </tr>
              );
              return singleStudent;
            })}
          </tbody>
        </table>
        <div
          className="card  border-1 alert-info vw-75 text-center"
          id="getStudentList"
          available="false"
        >
          <div className=" p-1" align="right">
            <SaveStudentList />
          </div>
        </div>
      </div>
    </>
  );
};

export default ClassStudentListViewComponent;
